import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AggregatoCassette } from '../../models/cassetta/aggregato.cassette.model';
import { EnvironmentService } from '../../services/environment/environment.service';
import { EventsService } from "../../services/events/events.service";
import { PunteggioService } from '../../services/punteggio/punteggio.service';
import { UserInfo } from '../../services/authentication/authentication.service';
import { PopupLivelliPage } from 'src/app/pages/popups/popup-livelli/popup-livelli.page';

export enum ScoreSize {
  small = 'small',
  normal = 'normal'
}

@Component({
  selector: 'score-bar',
  templateUrl: './score-bar.component.html',
  styleUrls: ['./score-bar.component.scss'],
})

export class ScoreBarComponent {
  userInfo: UserInfo;
  aggregato: AggregatoCassette;
  livello: string = 'Start';
  punteggioAcquisito: string = "0";
  punteggioAttesa: string = "0";
  numeroAmici:string= "0"
  @Input() size: ScoreSize = ScoreSize.normal;
  private handler: () => void;

  constructor(public punteggioService: PunteggioService, private events: EventsService,
              public modalCtrl: ModalController) {
                this.getPunteggio();
                this.handler = () => {
                  this.punteggioService.calcoloPunteggio();
                };
                this.events.subscribe(PunteggioService.PUNTEGGIO_AGGIORNATO, () => {
                  this.getPunteggio();
                });
               this.events.subscribe(EnvironmentService.ON_USER_REFRESH, this.handler);
              }

  getPunteggio() {
    this.livello = this.punteggioService.getLivello();
    this.punteggioAcquisito = this.punteggioService.getPunteggioAcquisito().toString();
    this.punteggioAttesa = this.punteggioService.getPunteggioInAttesa().toString();
  }

  setSize() {
    if (this.size === 'small') return 'small-score';
    else return 'normal-score'
  }

 async showPopupGuida() {
    let modal =  await this.modalCtrl.create({
                component:PopupLivelliPage,
                cssClass:['popup-livelli']
              });
    await modal.present();
  }

}

