import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class EnvironmentService {

// ICONA G&R
  public static readonly GAIN_RELAX_ICON_ACTIVE_URL: string = '/icon_gain_relax_active.png';

// CONFIG CONTROL
  public static readonly  BASIC_AUTH  = "";
  public static readonly  CONFIG_CONTROL = environment.WEB_APP + "config/config";


// TRANSFERS
  readonly CTOKEN_REQUEST = environment.TRANSFERS + "/?f=";
  readonly UPLOAD_POST = environment.TRANSFERS + "/czxbT.php";
  readonly REQUEST_UPLOAD_FILE = environment.API_ENDPOINT  + '/userdata/request';
  readonly UPLOAD_FILE = environment.API_ENDPOINT  + '/userdata/upload';
  readonly COMMIT_UPLOAD_FILE = environment.API_ENDPOINT  + '/userdata/commit';
  readonly GET_DOCUMENT= environment.API_ENDPOINT  +'/document';


//ZEN DESK
  public static readonly ZEN_DESK: string = 'https://weople.zendesk.com/hc/it';
  readonly MORE_INFO_SOCIAL_URL: string = EnvironmentService.ZEN_DESK + '/articles/360025211051-Come-si-attivano-le-cassette-di-sicurezza-nel-caveau-';
  readonly MORE_INFO_LOYALTY_URL: string = EnvironmentService.ZEN_DESK + '/articles/360025211051-Come-si-attivano-le-cassette-di-sicurezza-nel-caveau-';
  readonly MORE_INFO_ACCOUNT_URL: string = EnvironmentService.ZEN_DESK + '/articles/360025211051-Come-si-attivano-le-cassette-di-sicurezza-nel-caveau-';
  readonly MORE_INFO_ECOMMERCE_URL: string = EnvironmentService.ZEN_DESK + '/articles/360025211051-Come-si-attivano-le-cassette-di-sicurezza-nel-caveau-';
  readonly GUIDA_ONBOARDING_URL: string = EnvironmentService.ZEN_DESK + '/articles/360025211291-Che-cosa-sono-i-Wecoin-e-come-rappresentano-il-valore-dell-investimento-dei-dati- ';
  readonly MORE_INFO_ATTESA_URL: string = EnvironmentService.ZEN_DESK + '/articles/360024913932-La-ricompensa-sar%C3%A0-immediata-Perch%C3%A8-i-wecoin-sono-in-attesa-';
  readonly SALVADANAIO_URL: string = EnvironmentService.ZEN_DESK + '/sections/360003899292-Guadagni';
  readonly MORE_INFO_VINCITE_URL: string = EnvironmentService.ZEN_DESK + '/articles/360025211331-Come-funzionano-i-livelli-di-investimento-';
  readonly POPUP_OFFERTE_GUIDA_URL: string = EnvironmentService.ZEN_DESK + '/articles/360025211291-Che-cosa-sono-i-Wecoin-e-come-rappresentano-il-valore-dell-investimento-dei-dati-';
  readonly DOMANDE_RISPOSTE: string = EnvironmentService.ZEN_DESK;
  readonly CONTATTACI: string = EnvironmentService.ZEN_DESK + '/requests/new';


  // WEOPLE SITE
  readonly PRIVACY_URL: string = environment.WEB_SITE +'/privacy';
  readonly TERMS_URL: string = environment.WEB_SITE +'/terms#estese';
  readonly CODICE_ETICO_URL: string = environment.WEB_SITE +'/chi-siamo#link-etico';
  readonly PAGINA_NEWS_URL: string = environment.WEB_SITE +'/news';
  readonly PAGINA_TRASPARENZA_URL: string = environment.WEB_SITE +'/terms';
  readonly PAGINA_CHI_SIAMO: string =environment.WEB_SITE +'/chi-siamo';
  readonly PAGINA_FACEBOOK_WEOPLE: string = "https://www.facebook.com/WeopleSpace/";
  readonly GUIDA_CAVEAU_URL: string = environment.WEB_SITE +'/#caveau';
  readonly GUIDA_GAIN_RELAX_URL: string = environment.WEB_SITE +'/#gain-relax';
  readonly GUIDA_DIRITTI_URL: string = environment.WEB_SITE +'/#diritti';

  // API
  readonly LOGIN = environment.API_ENDPOINT + '/oauth/token';
  readonly GET_USER = environment.API_ENDPOINT  + '/profile'; //GET
  readonly REGISTRAZIONE = environment.API_ENDPOINT  + '/sign_up'; //POST
  readonly FORGOT_PASSWORD = environment.API_ENDPOINT  + '/passwords'; //POST
  readonly CHANGE_PASSWORD = environment.API_ENDPOINT  + '/profile/change_password'; //PUT
  readonly UPDATE_USER = environment.API_ENDPOINT  + '/profile'; //PUT
  readonly CONFIRM_EMAIL = environment.API_ENDPOINT  + '/confirmations'; //POST
  readonly UPLOAD_AVATAR = environment.API_ENDPOINT  + '/profile/avatar'; //POST
  readonly LIKE_CUSTOM_OFFERS = environment.API_ENDPOINT  + '/activities'; //POST
  readonly GET_LIKE_CUSTOM_OFFERS = environment.API_ENDPOINT  + '/activities2'; //GET
  readonly GET_NOTIFICATIONS = environment.API_ENDPOINT  + '/notifications'; //GET
  readonly REGISTER_DEVICE = environment.API_ENDPOINT  + '/devices'; //POST
  readonly CHALLENGES = environment.API_ENDPOINT  + '/challenges'; //GET
  readonly CHALLENGES_CATEGORIES = environment.API_ENDPOINT  + '/challenges/categories'; //GET
  readonly CHALLENGES_RESPONSES = environment.API_ENDPOINT  + '/challenges/responses'; //GET
  readonly REWARDS = environment.API_ENDPOINT  + '/rewards'; //GET
  readonly OFFERS = environment.API_ENDPOINT  + '/offers'; //GET
  readonly PREFERENCES = environment.API_ENDPOINT  + '/preferences'; //GET
  readonly RIGHT_REQUESTS = environment.API_ENDPOINT  + '/rightrequest';
  readonly PORTABILITY = environment.API_ENDPOINT  + '/portability';
  readonly COMPANIES_LIST = environment.API_ENDPOINT  +'/companies';

  // INVESTI / CASSETTE
  readonly GET_CASSETTE = environment.API_ENDPOINT  + '/vaults'; // GET ?userId=[user_id], ?vCategory=[category_type]
  readonly DELETE_CASSETTA = environment.API_ENDPOINT  + '/vaults'; // DELETE /[vault_id]
  readonly SAVE_CASSETTA = environment.API_ENDPOINT  + '/vaults'; // POST or PUT
  readonly GET_AGGREGATO = environment.API_ENDPOINT  + '/vaults/counters'; // GET ?userId=[user_id]
  readonly GET_LOCKS = environment.API_ENDPOINT  + '/vaults/locks'; // GET ?userId=[user_id]
  readonly VAULT = environment.API_ENDPOINT  + '/vaults'; // DELETE /[vault_id]
  readonly UPLOAD_DOCUMENT = environment.API_ENDPOINT  + '/document';
  readonly SUGGESTION = environment.API_ENDPOINT  + '/suggestions'; // GET ?type=[type]&term=[searchTerm]

  //RSS
  readonly INTERESTS_RSS = environment.API_ENDPOINT  + '/interestsrss';

  //GOOGLE DRIVE
  readonly GOOGLE_LOGIN = environment.API_ENDPOINT  + '/google/login';
  readonly GOOGLE_DRIVE = environment.API_ENDPOINT  + '/google/drive/auth';
  readonly GOOGLE_PORTABILITY = environment.API_ENDPOINT  + '/google/portability/auth';

  //AMAZON
  readonly AMAZON_LOGIN = environment.API_ENDPOINT + '/amazon/login';

  // REFERRAL CODE
  readonly REFERRAL_CODE = environment.API_ENDPOINT  + '/referral_codes'; // GET
  readonly REFERRALS = environment.API_ENDPOINT  + '/referrals'; // GET
  readonly GET_PUBLIC_KEY = environment.API_ENDPOINT  + '/referrals'; // MODIFICARE?
  readonly UPLOAD_ENCRYPTED_PASSWORD = environment.API_ENDPOINT  + '/referrals'; // MODIFICARE?

  //PROVINCE
  readonly PROVINCE = environment.API_ENDPOINT  + '/province'; //GET
  readonly NATIONS = environment.API_ENDPOINT  + '/nations'; //GET

  //DELEGATIONS
  readonly DELEGATIONS = environment.API_ENDPOINT  + '/delegations'; //GET
  readonly ALTER_DELEGATIONS = environment.API_ENDPOINT  + '/delegations'; //PUT
  readonly DELETE_DELEGATIONS = environment.API_ENDPOINT  + '/delegations'; //DELETE

  // PUNTEGGIO
  readonly GET_METRICS = environment.API_ENDPOINT  + '/metrics'; // GET

  //GOOGLE LOGIN -still used??
  readonly GOOGLE_LOGIN_DATA = 'https://www.googleapis.com/plus/v1/';
  public static readonly ON_USER_REFRESH: string = 'drawer_user_refresh';
  public static readonly ON_BADGE_REFRESH: string = 'drawer_badge_refresh';
  public static readonly ON_GOOGLE_LOGIN_WEB: string = 'on_google_login_web';

  //POPUP HTML
  readonly POPUP_REMOVE_CAVEAU = environment.API_ENDPOINT  + '/content_blocks/remove_caveau_template'; //GET
  readonly POPUP_ADD_CAVEAU_TEMPLATE = environment.API_ENDPOINT  + '/content_blocks/add_caveau_template';

  // XML TO JSON
  readonly XML_PARSER = 'https://api.rss2json.com/v1/api.json'; // GET ?rss_url=[xml_url]
  readonly WEOPLE_WEBSITE_SHARE = environment.WEB_SITE ;
  readonly WEOPLE_DOWNLOAD_APP = environment.WEB_SITE +'/#download-app';

  // TEXTS
  readonly SHARE_REFERRAL_CODE_TEXT = 'Ciao, ti mando questo codice amico per iscriverti a Weople, la prima Banca per investire i tuoi dati, proteggerli ed agire i tuoi diritti di privacy, gratis e senza sforzo. Io l’ho già fatto! Scarica l’App e unisciti a Weople! ';
  readonly GENERIC_ERROR_MESSAGE = 'La connessione sicura al server non è riuscita, si prega di riprovare'
  readonly DOCUMENT_ERROR_MESSAGE = ' Il caricamento dell’immagine non è andato a buon fine, si prega di controllare la connessione e riprovare'
}


