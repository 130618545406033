import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { EnvironmentService } from "../environment/environment.service";
import { EventsService } from "../events/events.service";
import { MyPlatformService } from "../my-platform//my-platform.service";

@Injectable()
export class MyInAppBrowserService {
  // Mobile App
  // Open always in browser

  // Mobile Browser
  // Open weople.space in app
  // external websites new tab
  //
  // Desktop browser
  // Open always new tab

  userInfo;

  constructor(private myPlatform: MyPlatformService, public events: EventsService){
    events.subscribe(EnvironmentService.ON_USER_REFRESH, (data) => {
      if(data != undefined){
        this.userInfo = data.data;
      }
    });
  }

  create(url, target="_system", appOverride=true){
    let webDomain = "weople.space";
    if(appOverride){
      if(this.myPlatform.isMobileWeb && url.includes(webDomain)){
        target = "_blank";
      }
    }
    if(url.includes(webDomain)){
      url = this.addTokenToUrl(url);
    }
    return Browser.open({url: url, windowName: target});
  }

  private addTokenToUrl(url: string):string{
    let hash = '';
    if(url.includes("#")){
      let uArr = url.split("#");
      url = uArr[0];
      hash = '#' + uArr[1];
    }
    if(this.userInfo){
      let token = 'ref=' + this.userInfo.integration_id;
      if(url.includes('?')) {
        url += '&' + token;
      }else {
        url += '?' + token;
      }
    }
    return url + hash;
  }

}
