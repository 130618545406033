import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {Platform} from "@ionic/angular";
import {Offer} from "../../models/offer.model";
import {OfferService} from "../offer/offer.service";


export interface Mgm {
  heading: string,
  body: string,
  details: string,
}

@Injectable()
export class AppStateService {

  static instance: AppStateService;

  unreadNotifications:number;


  // ===== UTILITIES ============
  private locale: string;
  private popupPremiShown: boolean;
  private popupRightsShown: boolean;
  private popupInvestmentsShown: boolean;
  private popupSalvadanaioShown: boolean;
  private popupOfferteShown: boolean;
  private popupVinciteShown: boolean;
  private counterCaveauModal:number = 0;
  private caveauAnimationSeen: boolean;
  private popupDocumentShown: boolean;
  private popupDocumentInfoShown: boolean;
  private gainAndRelaxFirstTime: boolean;
  private cassetteNeedRefresh: boolean;
  private rightsSuspended = true;

  // =============================

  // ===== TEXTS AND CONTENTS ====
  private videoUrlInApp: string = "";
  private videoUrl: string = "";
  private guida: string = "";

  private mgm: Mgm;
  private piggybank: Offer;
  private winnings: Offer;

  private zipTarFile:any;

  // =============================

  constructor(public platform: Platform, private offerService: OfferService) {
    AppStateService.instance = AppStateService.instance || this;
  }
  // ===== UTILITIES ============

  setLocale(locale:string) {
    this.locale = locale;
  }

  getLocale(): string {
    return this.locale;
  }

  setPopupPremiShown(shown:boolean) {
    this.popupPremiShown = shown;
  }

  isPopupPremiShown(): boolean {
    return this.popupPremiShown;
  }

  isRightSuspended(): boolean{
    return this.rightsSuspended
  }

  setPopupRightsShown(shown:boolean) {
    this.popupRightsShown = shown;
  }

  isPopupRightsShown(): boolean {
    return this.popupRightsShown;
  }

  setPopupInvestmentsShown(shown:boolean) {
    this.popupInvestmentsShown = shown;
  }

  isPopupInvestmentsShown(): boolean {
    return this.popupInvestmentsShown;
  }

  setCaveauAnimationSeen(value:boolean) {
    this.caveauAnimationSeen = value;
  }

  isCaveauAnimationSeen(): boolean {
    return this.caveauAnimationSeen;
  }

  setPopupSalvadanaioShown(shown:boolean) {
    this.popupSalvadanaioShown = shown;
  }

  isPopupSalvadanaioShown(): boolean {
    return this.popupSalvadanaioShown;
  }

  setPopupOfferteShown(shown:boolean) {
    this.popupOfferteShown = shown;
  }

  isPopupOfferteShown(): boolean {
    return this.popupOfferteShown;
  }

  setPopupVinciteShown(shown:boolean) {
    this.popupVinciteShown = shown;
  }

  isPopupVinciteShown(): boolean {
    return this.popupVinciteShown;
  }

  incrementAnimationCounter() {
    this.counterCaveauModal++;
    return this.counterCaveauModal;
  }

  isPopupDocumentShown(){
    return this.popupDocumentShown;
  }

  setPopupDocumentShown(shown:boolean) {
    this.popupDocumentShown = shown;
  }

  isPopupDocumentInfoShown(){
    return this.popupDocumentInfoShown;
  }

  setPopupDocumentInfoShown(shown:boolean) {
    this.popupDocumentInfoShown = shown;
  }


  setGainAndRelaxFirstTime(status: boolean) {
    this.gainAndRelaxFirstTime = status;
  }

  getGainAndRelaxFirstTime(): boolean {
    return this.gainAndRelaxFirstTime;
  }

  setCassetteNeedRefresh(value: boolean) {
    this.cassetteNeedRefresh = value;
  }

  getCassetteNeedRefresh(): boolean {
    return this.cassetteNeedRefresh;
  }

  // ===== TEXTS AND CONTENTS GOT FROM STELLAR ====

  getVideoUrlInApp(): string {
    return this.videoUrlInApp;
  }

  getVideoUrlShare(): string {
    return this.videoUrl;
  }

  getGuida(): string {
    return this.guida;
  }

  getMgm(): Mgm {
    if (this.mgm) {
      return this.mgm;
  }else return {
      heading: "", body: "", details:""
    }
  }

  getWinnings(): Offer{
    return this.winnings;
  }

  getPiggybank(): Offer{
    return this.piggybank;
  }

  getZipTarFile(): any {
    return this.zipTarFile;
  }
  setZipTarFile(zipTarFile){
    this.zipTarFile = zipTarFile;
  }

  getContentFromBackend() {
    const observable = new Observable((observer) => {
      this.offerService.getOffers('content',).subscribe({  next: (response) => {
        let offers: Offer[] = response.offers;
        offers.forEach(value => {
          // se è un offer di categoria "mgm"
          if (value.category == "mgm"){
           this.mgm = {
              heading: value.heading,
              body: value.body,
              details: value.details,
            }
          } else {   // altrimenti fai il solito
            switch(value.internal_name){
              case "piggybank":
                this.piggybank = value;
                break;
              case "winnings":
                this.winnings = value;
                break;
              case "video_url":
                this.videoUrl = value.body;
                break;
              case "video_url_in_app":
                this.videoUrlInApp = value.body;
                break;
            }
          }
        });
        observer.next('Contents successfully downloaded');
        observer.complete();
      }, error: (error) => {
        console.error('getContentFromBackend', error);
        observer.error(error);
        observer.complete();
      }})
    });
    return observable;
  }

}