import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from "rxjs";
import { Authentication } from "../authentication/authentication.service";
import { EnvironmentService } from "../environment/environment.service";
import { MemberPreference } from '../../models/memberPreferences/memberPreference';

@Injectable()
export class MemberPreferenceService {

  private allPreferences: MemberPreference[]; // la response della chiamata findAll
  auth: Authentication;
  readonly INTERESTS_UPDATED = "INTERESTS_UPDATED";

  constructor(public httpClient: HttpClient, private env: EnvironmentService, public injector: Injector) {
    setTimeout(() => this.auth = injector.get(Authentication));
  }

  findAll(): Observable<any> {

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    return this.httpClient.get(this.env.PREFERENCES, {headers: headers});
  }

  update(name, selectedList): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let body = {
      name: name,
      domain: selectedList
    };

    return this.httpClient.put(this.env.PREFERENCES, body, {headers: headers});
  }

  // findInterests(): Observable<any> {
  //   let headers = {
  //     'Accept': 'application/vnd.stellar-v1+json'
  //   };
  //
  //   let params = {
  //     access_token: this.auth.getAccessToken()
  //   };
  //
  //   return this.httpClient.get<any>(this.env.PREFERENCES, {headers: headers, params:params})
  //     .pipe(
  //       map( res => {
  //         let index = res.data.findIndex(elem => {
  //           return elem.name === 'interessi'
  //         });
  //         const listOfInterests: DomainDetail[] = res.data[index].domain.list_with_details;
  //         const listOfSelectedInterests: string[] = res.data[index].domain.selected;
  //
  //         let newArray = [];
  //
  //         for (let interest of listOfInterests) {
  //           let obj: any = {};
  //           obj = Object.assign(interest);
  //           for (let selectedInterest of listOfSelectedInterests) {
  //             if (selectedInterest === interest.value) {
  //               obj.selected = true;
  //             } else obj.selected = false;
  //           }
  //
  //           newArray.push(obj);
  //         }
  //
  //         return newArray;
  //       })
  //     );
  // }

  setPreferencesInMemory(allPreferences) {
    this.allPreferences = allPreferences;
  }

  getPreferencesInMemory(): MemberPreference[]{
    return this.allPreferences;
  }


}
