import { CassettaVTypeCode } from "./cassetta.model";

export class AggregatoCassette {

  total: number;

  social: AggregatoCassetteCategoria = new AggregatoCassetteCategoria();
  account: AggregatoCassetteCategoria = new AggregatoCassetteCategoria();
  ecommerce: AggregatoCassetteCategoria = new AggregatoCassetteCategoria();
  loyalty: AggregatoCassetteCategoria = new AggregatoCassetteCategoria();

}

export class AggregatoCassetteCategoria {
  count: number = 0;
  status: AggregatoStatus = AggregatoStatus.empty;
  grabbed: number = 0;
  vTypeCode: CassettaVTypeCode[] = [];
  grants: number = 0;
}

export enum AggregatoStatus {
  active = 'active',
  grabbed = 'grabbed',
  empty = 'empty'
}
