import { Observable } from "rxjs";
import { Injectable, Injector } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Authentication } from "../authentication/authentication.service";
import { EnvironmentService } from '../environment/environment.service';


@Injectable()
export class RewardService {
  auth;
  constructor(private env: EnvironmentService, public httpClient: HttpClient,private injector: Injector){
    setTimeout(() => this.auth = this.injector.get(Authentication));
  }

  getRewards(category?: string): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let params = { };
    if (category){
      params['category'] = category;
    }
    return this.httpClient.get(this.env.REWARDS, {headers: headers, params:params});
  }
}
