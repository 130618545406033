import { Observable, timeout} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Authentication } from '../authentication/authentication.service'
import { EnvironmentService } from '../environment/environment.service';
;

export enum DocType {
  cartaDiIdentita = 'CID',
  passaporto = 'PAS',
  patente = 'PAT',
  permessoDiSoggiorno = 'SOG'
}

export enum DocSide {
  fronte = '1',
  retro = '2',
}

@Injectable()
export class DocumentService {

  constructor(public httpClient: HttpClient, private env: EnvironmentService, private auth: Authentication) {

  }

  uploadDocument(blob, userId: string, docType: string, docSide: DocSide): Observable<any> {

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let params = {
      userId: userId,
      docType: docType,
      docSide: docSide
    };

    let formData: FormData = new FormData();
    formData.append('docImg', blob, "docImg_" + docType + "_" + docSide + '.jpeg');
    return this.httpClient.post(this.env.UPLOAD_DOCUMENT, formData, { headers: headers, params: params }).pipe(timeout(40000));
  }
}
