import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from "@ionic/angular";
import { AppStorage } from '../app-storage/app-storage.service';
import { EnvironmentService } from "../environment/environment.service";




@Injectable()
export class UtilityService {

private httpClient: HttpClient;

  constructor(public alertCtrl: AlertController, public appStorage: AppStorage, public env: EnvironmentService,
                public handler: HttpBackend, public http: HttpClient) {
                this.httpClient = new HttpClient(handler);
              }

  test() {
    let obj1 = {
      uno: 'uno',
      due: {
        a1: 'uno',
        a2: {
          b1: 'daje',
          b2: {
            c1: 'rotto',
            c2: 'gino',
            c3: true,
            c4: 100
          }
        }
      },
      tre: ['abc', 'def', 'ghi']
    }

    let obj2 = {
      uno: 'uno',
      due: {
        a1: 'uno',
        a2: {
          b1: 'daje',
          b2: {
            c1: 'rotto',
            c2: 'gino',
            c3: true,
            c4: 100
          }
        }
      },
      tre: ['abc', 'ghi', 'def']
    }

    return this.isSame(obj1, obj2, false);
  }

  /**
  * Verifica se due oggetti sono uguali.
  * @param orderMatters Se l'ordine all'interno di un array è rilevante ai fini dell'uguaglianza
  */
  isSame(object1: any, object2: any, orderMatters: boolean): boolean {
    if(!(object1 instanceof Object)){
        return object1 == object2;
    }
    let keys1 = Object.keys(object1);
    let keys2 = Object.keys(object2);

    // se il numero di chiavi e' diverso, gli oggetti sono diversi
    if (!orderMatters){
      keys1.sort((a, b) => a.localeCompare(b));
      keys2.sort((a, b) => a.localeCompare(b));
      if(object1 instanceof Array){
        object1.sort((a, b) => a.localeCompare(b));
        object2.sort((a, b) => a.localeCompare(b));
      }
    }

    if(keys1.toString() != keys2.toString()){
      return false;
    }

    let sameStuff = true;
    try{
        keys1.forEach( (key1) => {
            let value1 = object1[key1];
            let value2 = object2[key1];
            let bothArrays = value1 instanceof Array && value2 instanceof Array;
            let bothObjects = value1 instanceof Object && value2 instanceof Object;

            switch(true){
                case bothArrays:
                // recursiveness on arrays values
                sameStuff = this.isSame(value1, value2, orderMatters)
                break;
                case bothObjects:
                // recursiveness on objects
                sameStuff = this.isSame(value1, value2, orderMatters);
                break;
                default:
                // if they're not the same instanceof this if below is always false
                // also if they're not arrays or objects but they are different is false
                sameStuff = value1 == value2;
            }

            if(!sameStuff){
                throw new Error("error");
            }
        });
    }catch{
        return false;
    }

    // gli oggetti sono uguali
    return sameStuff;
  }

  // il formato di birthdateForm deve essere del tipo: 1996-08-06
  checkMinorenne(birthdateForm:string, minAge: number) {
    let today = new Date();
    let birthdate = new Date(birthdateForm);
    let age = today.getFullYear() - birthdate.getFullYear();
    let m = today.getMonth() - birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }

    return age >= minAge ? null : true
  }


  getObjKey(obj:any, value:any) {
    return Object.keys(obj).find(key => obj[key] === value);
  }

  getPlaceholderAvatar(){
    let headers = {
      'Accept': 'image/png',
      'Content-Type': 'image/png'
    };
    return this.http.get("assets/imgs/icon_profile_picture/placeholder@3x.png", { headers: headers,  responseType: 'blob' });
  }

  copyCode(referral_code:string) {

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = referral_code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    navigator.clipboard.writeText(referral_code).then(()=> {
        console.log("copied")
      }).catch((err)=>
        {console.error(err)
      });
    document.body.removeChild(selBox);
  }

  copyService(el: any , referral_code?: string) {

    el = (typeof el === 'string') ? document.querySelector(el) : el;

    if(referral_code == undefined){
      referral_code = 'Not_available'
    }

    const iosReg = /ipad|ipod|iphone/i;

    if (iosReg.exec(navigator.userAgent) != null) {

      let editable = el.contentEditable;
      let readOnly = el.readOnly;

      el.contentEditable = true;
      el.readOnly = true;

      let range = document.createRange();
      range.selectNodeContents(el);


      let selection = window.getSelection();
      if(selection != null){
        selection.removeAllRanges();
        selection.addRange(range);
      }
      el.setSelectionRange(0, 999999);

      el.contentEditable = editable;
      el.readOnly = readOnly;

      document.execCommand('copy');
    }
    else {
      this.copyCode(referral_code);
    }
  }

  companyList(){
    let headers = {
      'Accept': 'application/json'
    }
    return this.httpClient.get(this.env.COMPANIES_LIST,{headers:headers});
  }


 async simpleAlert(title: string, subtitle: string){
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subtitle,
      buttons: ['Ok']
    });
    await  alert.present();
  }

  createChunks(file: File): Blob[] {
    const fileSize = file.size;
    const chunkSize = 1024 * 1024; // chunk da 1.05 MB
    let chunks: Blob[] = [];
    const forceSingleFile: boolean = false;  // carica sempre tutto con un file
    if (fileSize < chunkSize || forceSingleFile) {
      chunks.push(file);
      return chunks;
    }
    let end = 0;
    let lastChunkBits;
    for(let start = 0; start < fileSize; start += chunkSize) {
      lastChunkBits = fileSize - end;
      if (lastChunkBits < chunkSize) {
        end = start + lastChunkBits;
        } else end = start + chunkSize;
      chunks.push(file.slice(start, end));
    }
    return chunks;
  }

  public snakeToCamelCase(varName) {
    return varName.replace(/(\w)/g, (m) => {return m[1].toUpperCase();});
  }

}
