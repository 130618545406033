import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController} from '@ionic/angular';
import { Cassetta } from "../../models/cassetta/cassetta.model";
import { AppStorage } from "../app-storage/app-storage.service";
import { AlertTutorialUploadPage } from 'src/app/pages/alerts/alert-tutorial-upload/alert-tutorial-upload.page';

@Injectable()
export class PopupUtilService {

  public ALERT_TUTORIAL_UPLOAD_DONT_SHOW_AGAIN = "alertTutorialUploadDontShowAgain";

  constructor(public appStorage:AppStorage, public http: HttpClient, public modalCtrl: ModalController) { }


  async checkIfShowAlertTutorialUpload(cassetta,fromHelp): Promise<any>{
    this.appStorage.getItem(this.ALERT_TUTORIAL_UPLOAD_DONT_SHOW_AGAIN+cassetta.vTypeCode).then((res) => {
      if (res){
       return true}
      else {
        this.showAlertTutorialUpload(cassetta,fromHelp);
        return false;
      };
    }).catch(error => {
      console.error(error);
      return false;
    });
  }


  async  showAlertTutorialUpload(cassetta: Cassetta,fromHelp:boolean) {
    let modal =  await this.modalCtrl.create({
                component:AlertTutorialUploadPage,
                componentProps: { cassetta: cassetta, fromHelp:fromHelp },
                cssClass: 'popup-alert-tutorial'
                })
    await modal.present();
  }
}
