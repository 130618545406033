<ion-content class="ion-padding-left ion-padding-right ion-text-center">
  <div class="vertical-center-container">
    <ion-grid>
      <ion-row>
        <ion-col>
          <p class="popup-subtitle">
            Attenzione!
          </p>
          <hr class="divider">
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p class="text">
            Sei sicur di voler rimuovere la tua immagine profilo?
          </p>
        </ion-col>
      </ion-row>
      <ion-row size="12">
        <ion-col size="6">
            <ion-button color="light" expand="block" mode="ios" (click)="closeModal()">ANNULLA</ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button color="primary" expand="block" mode="ios" (click)="removeAvatarFromWeb()">CONFERMA</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
