  <ion-content class="ion-padding-left ion-padding-right ion-text-center no-bounce" id="section-99">
    <div class="vertical-center-container">
      <ion-grid>
        <ion-row>
          <ion-col>
            <p class="popup-subtitle">
              Attenzione!
            </p>
            <hr class="divider">
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p class="text">
              Ricorda che qualora volessi rileggere lo potrai fare nella sezione <b>Aiuto</b>.
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-button color="light" expand="block" mode="ios" (click)="closeModal()" class="btn-left">
              RESTA
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button color="primary" expand="block" mode="ios" (click)="goToFirstPage()" class="btn-left">
              ESCI
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-content>