<ion-content class="ion-text-center ion-padding-left ion-padding-right">
  <div class="vertical-center-container">
    <ion-grid class="higher-grid">
      <ion-row>
        <ion-col>
          <img class="brand-image" src="assets/imgs/icon_modal/icon_modal_elimina_account@3x.png" alt="icona faccina triste">
          <hr class="divider">
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p class="popup-subtitle">Eliminazione del profilo</p>
          <p class="text">
            Procedendo con questa azione, elimini il tuo profilo e i dati dal sistema Weople.
            Nel caso volessi ritornare, dovrai procedere con una nuova iscrizione.
            In alternativa, puoi sospendere le tue cassette di sicurezza.
            Sei sicuro di voler procedere?
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="container-fixed-bottom buttons">
    <ion-grid>
      <ion-row size="12">
        <ion-col size="6">
          <ion-button color="light" expand="block" mode="ios" (click)="closeModal()" class="btn-left">
            ANNULLA
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button color="primary" expand="block" mode="ios" (click)="deleteUser()">
            CONFERMA
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>