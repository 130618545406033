import { Component } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';

@Component({
  selector: 'popup-ultimo-permesso',
  templateUrl: './popup-ultimo-permesso.page.html',
})

export class PopupUltimoPermessoPage {

  constructor(private modalCtrl: ModalController,public navCtrl: NavController) {
               }


  closeModal() {
    this.modalCtrl.dismiss();
  }

}
