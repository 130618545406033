// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_ENDPOINT: 'https://staging.api.weople.space/api/v4',
  WEB_APP: 'https://app.staging.weople.space/',
  TRANSFERS: "https://staging-transfers.weople.space",
  WEB_SITE: "https://staging.weople.space",
  CLIENT_ID: '4ae1f1e19ef394fd7299fbeb22a1ff9172510dc325a8be43aac575804ec58a95',
  CLIENT_SECRET: 'bf88a26b42c3fcb253422ae472bf02d65d0ea52d9d8b9ab1fbf9428cf14b71cd',
  ANDROID_CLIENT_ID: '6bd8b5c8c511cd496a2258632e20f24dd7bdd6f090eae3b9ad90fb17393c69b5',
  ANDROID_CLIENT_SECRET: '5decd77a60f620c0bbe755cad37f4c2c8a2c5500cb2bbcda21ba4c9502032f78',
  IOS_CLIENT_ID: '18ccac95ea5db52f909187a30ddc2425c86afb17032cbc79656d773e6c4dc077',
  IOS_CLIENT_SECRET: '66ff75ccfb32c1e258ac86f81394554756718b76af6c84565e176043e32727ea',
  GOOGLE_CLIENT_ID: '400442372325-subl8g0s6giteem5ge85m389pth5hnk5.apps.googleusercontent.com',
  AMAZON_CLIENT_ID: 'amzn1.application-oa2-client.3a63af72f50a4a0d8b2e8cc32b4a02cd'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
