import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Delegation } from "../../models/delegation";
import { Authentication } from '../authentication/authentication.service'
import { EnvironmentService } from '../environment/environment.service';

@Injectable()
export class DelegationService {

  constructor(private auth: Authentication, private env: EnvironmentService, public httpClient: HttpClient){
  }

  getDelegation(request_type): Observable<any> {

      let headers = {
        'Accept': 'application/json',
        'Authorization': 'Token '+ this.auth.getAccessToken()
      };
      let params = {"delegation_state":request_type}
      return this.httpClient.get(this.env.DELEGATIONS, {params:params,headers: headers,responseType:'blob'});
    }


  alterDelegation(state): Observable<any> {
      let headers = {
        'Accept': 'application/json',
        'Authorization': 'Token '+ this.auth.getAccessToken()
      };
      let params = {
        "delegation_state":state
      }
      return this.httpClient.put<Delegation>(this.env.DELEGATIONS, params,{headers: headers});
    }

  deleteDelegation(): Observable<any> {
      let headers = {
        'Accept': 'application/json',
        'Authorization': 'Token '+ this.auth.getAccessToken()
      };
      return this.httpClient.delete<Delegation>(this.env.DELEGATIONS, {headers: headers});
    }

}


