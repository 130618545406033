import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MyInAppBrowserService } from '../my-in-app-browser/my-in-app-browser.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Offer } from '../../models/offer.model';
import { EnvironmentService } from '../environment/environment.service';
import { MyPlatformService } from '../my-platform/my-platform.service';

declare let window: any;
export interface FbWebLoginReponse {
  authResponse: any;
  status: string
}

@Injectable()
export class SocialService {

  fbWeb;

  constructor(public http: HttpClient, private myPlatform: MyPlatformService,
              private socialSharing: SocialSharing,
              private iab: MyInAppBrowserService, private env: EnvironmentService) {
    this.fbWeb = window.FB;

  }


  facebookShare(title: string, url: string , codiceAmico) {
     const fbObservable = new Observable(observer => {
         this.shareWithFacebookWeb(url, codiceAmico)
        });
        return fbObservable;
  }


  shareWithMail(subject, message) {
    this.socialSharing.canShareViaEmail().then(() => {
      // Sharing via email is possible

      this.socialSharing.shareViaEmail('<b>Uella</b>', subject, ['']).then(() => {
        // Success!
      }).catch(() => {
        // this.simpleAlert("Errore", "Si è verificato un errore durante l'invio dell'email")
      });

    }).catch(() => {
      // Sharing via email is not possible
      // this.simpleAlert("Errore", "La condivisione non è supportata da questo dispositivo")
    });
  }

  shareWithSms(message) {
    this.socialSharing.shareViaSMS(message, '').then(() => {
      // Success!
    }).catch(() => {
      //this.simpleAlert("Errore", "Si è verificato un errore durante l'invio del sms")
    });
  }

  shareWithWhatsapp(message, url, codiceAmico) {
    if (this.myPlatform.isApp) {
      this.socialSharing.shareViaWhatsApp(message).then(() => {
        // Success!
      }).catch(() => {
        //this.simpleAlert("Errore", "Si è verificato un errore durante l'invio su Whatsapp")
      });
    }
    else{
      this.shareWithWhatsappWeb(url, codiceAmico)
    }

  }

  shareWithFacebook(offer: Offer , codiceAmico){
    this.facebookShare(offer.heading, this.env.WEOPLE_DOWNLOAD_APP , codiceAmico).subscribe({next:(res) => {
      console.log(res)
  }, error:(error) => {
      console.error('Facebook share: error ', error);
    }})
  }

  shareWithFacebookWeb(linkUrl, codiceAmico?){
    this.shareWithFacebookWebQuote(linkUrl, codiceAmico);
  }

  shareWithFacebookWebQuote(linkUrl, codiceAmico?){
    let shareUrl = "https://www.facebook.com/sharer/sharer.php?u="+linkUrl;
    shareUrl += "&quote=Ecco il mio codice amico: " + codiceAmico;
    this.iab.create(shareUrl, "_system");
  }

  shareWithWhatsappWeb(linkUrl, codiceAmico){
    let shareUrl = "https://api.whatsapp.com/send?text="+linkUrl+codiceAmico;

    this.iab.create(shareUrl, "_system");
  }

  shareWithMessenger(message, codiceAmico) {

    this.socialSharing.shareViaFacebookWithPasteMessageHint(message, codiceAmico).then(() => {
      console.log('success')
    }).catch(() => {
      console.log('error');
    });

  }
  shareWithMessengerProfile(message, image?, url?){
    this.socialSharing.shareViaFacebook(message,url).then(() => {
      console.log('success')
    }).catch(() => {
      console.log('error');
    });
  }

}
