import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Authentication } from '../../../services/authentication/authentication.service';
import { SpinnerService } from "../../../services/spinner/spinner.service";
import { UtilityService } from '../../../services/utility/utility.service';


@Component({
  selector: 'popup-remove-avatar',
  templateUrl: './popup-remove-avatar.page.html',
  styleUrls: ['./popup-remove-avatar.page.scss'],
})


export class PopupRemoveAvatarComponent {

  text: string;

  constructor(private auth: Authentication, private modalCtrl:ModalController,
    private spinnerService: SpinnerService,private utils: UtilityService) {
      }

  closeModal() {
    this.modalCtrl.dismiss(false);
  }

  removeAvatarFromWeb(){
    this.auth.removeAvatar().subscribe({ complete: () => {
      this.spinnerService.stop();
      this.modalCtrl.dismiss(true);
    }, error: (error) => {
      console.error(error)
      this.spinnerService.stop();
      this.modalCtrl.dismiss(true);
    }});
  }

}
