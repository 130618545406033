import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class MyPlatformService {

  isAndroid = false
  isApp = false
  isBrowser = false
  isMobile = false
  isMobileWeb = false
  isWeb = false
  isIos = false

  constructor(private platform: Platform){
    this.isAndroid = platform.is('android');
    this.isIos = platform.is('ios');
    this.isMobile = platform.is('mobile');
    this.isApp = this.isAndroid && document.URL.includes("localhost");
    this.isMobileWeb = platform.is('mobile') && !this.isApp;
    this.isWeb = platform.is('desktop');
    this.isBrowser = this.isWeb || this.isMobileWeb;
  }

  public getPlatform(){
    return this.platform;
  }

}
