import { Component, } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DelegationService } from '../../../services/delegation/delegation.service';
import { Cassetta } from '../../../models/cassetta/cassetta.model';
import { PopupHelpDelegationPage } from '../popup-help-delegation/popup-help-delegation.page';

@Component({
  selector: 'app-popup-create-delegation',
  templateUrl: './popup-create-delegation.page.html',
  styleUrls: ['./popup-create-delegation.page.scss'],
})
export class PopupCreateDelegationPage{

  cassetta: Cassetta;

  constructor(private delegation: DelegationService,
              public modalCtrl: ModalController) {}

  createDelegation() {
    let state = "IN CREATION";
    this.delegation.alterDelegationVault(state,this.cassetta.vId).subscribe({next: () => {
      }, error: (error) => {
        console.error(error)}
    });
    this.modalCtrl.dismiss({creation: true});
  }

  async showHelp() {
    let modal = await this.modalCtrl.create({
      component: PopupHelpDelegationPage,
      componentProps: {
        cassetta: this.cassetta,
        fromConsent: false
      },
      cssClass: ['popup-delegations'],
      backdropDismiss: false
    });
      modal.present();
  }

  closeModal() {
    this.modalCtrl.dismiss({creation: false});
  }

}
