
import {Component} from '@angular/core';
import { ModalController, NavController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-popup-user-has-no-document',
  templateUrl: './popup-user-has-no-document.page.html',
})

export class PopupUserHasNoDocumentPage {

  hasCassette: boolean;
  fromRights: boolean;
  fromDelegation: boolean;

  constructor(public ModalCtrl: ModalController, public navCtrl: NavController,
               public navParams: NavParams) {

  }

  closeModal(goToUpload: boolean) {
    this.ModalCtrl.dismiss({
      goToUpload:goToUpload});
  }

}
