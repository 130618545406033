import { Component } from '@angular/core';
import { ModalController, NavController, } from '@ionic/angular';
import { EnvironmentService } from '../../../services/environment/environment.service';
import { MyInAppBrowserService } from '../../../services/my-in-app-browser/my-in-app-browser.service';
@Component({
  selector: 'app-popup-livelli',
  templateUrl: './popup-livelli.page.html',
  styleUrls: ['./popup-livelli.page.scss'],
})
export class PopupLivelliPage {

  constructor(public navCtrl: NavController, public modalCtrl: ModalController,
     private iab: MyInAppBrowserService, private env: EnvironmentService) {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  goToLinkAttesa(){
    this.iab.create(this.env.MORE_INFO_ATTESA_URL, "_blank")
  }

}
