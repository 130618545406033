import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Authentication } from "../authentication/authentication.service";
import { EnvironmentService } from '../environment/environment.service';

@Injectable()
export class ChallengeService {

  constructor(public httpClient: HttpClient, private env: EnvironmentService,
              private auth: Authentication) {

  }

  getChallenges(type: string, category: string): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let params = {
      category:category,
      type:type,
      // respondable:'true'
    };
    return this.httpClient.get(this.env.CHALLENGES, {headers: headers, params:params});
  }

  respondWithMultipleAnswers(id: number, answers: string[]): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let params = {
      id:id.toString(),
    };

    let body = new FormData();
    body.append('answer[text][]', answers.toString());

    return this.httpClient.post(this.env.CHALLENGES, body, {headers: headers, params:params})
  }

  respondWithSingleAnswer(id: number, answer: string): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let params = {
      id:id.toString(),
    };

    let body = new FormData();
    body.append('answer[text]', answer);

    return this.httpClient.post(this.env.CHALLENGES, body, {headers: headers, params:params})
  }

  respondMultiQuestionItems(id: number, items: any): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let params = {
      id:id.toString(),
    };

    return this.httpClient.post(this.env.CHALLENGES, items, {headers: headers, params:params})
  }


  respondUpdateProfile(updateProfileInfo: any): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let body = new FormData();


    let keys = Object.keys(updateProfileInfo);
    for(let key of keys) {
      if (key!='id'){
        body.append('answer[profile]['+key+']', updateProfileInfo[key]);
      }
    }

    return this.httpClient.post(this.env.CHALLENGES, body, {headers: headers})
  }

  getChallengesResponses(): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    return this.httpClient.get(this.env.CHALLENGES_RESPONSES, {headers: headers});
  }

}
