<ion-content class="ion-padding-left ion-padding-right ion-text-center ">
  <ion-icon name="close-outline" (click)="closeModal()" class="btn-close"></ion-icon>
  <img class="top-image" src="assets/imgs/wecoin/wecoin_gold@3x.png" alt="wecoin logo">
  <div class="divider ion-align-items-center"></div>
  <p class="subtitle">Livelli Wecoin</p>
  <ion-grid>
    <ion-row>
      <ion-col size="5" class="ion-no-padding ion-align-self-center">
        <p class="text" text-left>Livello Start</p>
      </ion-col>
      <ion-col size="3" class="ion-no-padding ion-align-self-center">
        <p class="puntini">. . . . . . . .</p>
      </ion-col>
      <ion-col size="4" class="ion-no-padding ion-align-self-center">
        <p class="text">
          <b>1-100</b>
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="5" class="ion-no-padding ion-align-self-center">
        <p class="text" text-left>Livello Bronzo</p>
      </ion-col>
      <ion-col size="3" class="ion-no-padding ion-align-self-center">
        <p class="puntini">. . . . . . . .</p>
      </ion-col>
      <ion-col size="4" class="ion-no-padding ion-align-self-center">
        <p class="text">
          <b>101-200</b>
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="5" class="ion-no-padding ion-align-self-center">
        <p class="text" text-left>Livello Argento</p>
      </ion-col>
      <ion-col size="3" class="ion-no-padding ion-align-self-center">
        <p class="puntini">. . . . . . . .</p>
      </ion-col>
      <ion-col size="4" class="ion-no-padding ion-align-self-center">
        <p class="text">
          <b>201-300</b>
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="5" class="ion-no-padding ion-align-self-center">
        <p class="text" text-left>Livello Oro</p>
      </ion-col>
      <ion-col size="3" class="ion-no-padding ion-align-self-center">
        <p class="puntini">. . . . . . . .</p>
      </ion-col>
      <ion-col size="4" class="ion-no-padding ion-align-self-center">
        <p class="text">
          <b>301-400</b>
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
  <p class="text-connessione">
    Quando superi i 400 Wecoin, ogni punto in più aumenterà le tue probabilità di
    essere estratto per i premi nella sezione vincite
  </p>
  <p class="text-small ion-text-center">Perchè alcuni Wecoin sono in attesa?
    <b class="text-small link-guida" color="primary" (click)="goToLinkAttesa()"><u>Guida</u></b>
  </p>
</ion-content>