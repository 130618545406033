export class Test {

  public static readonly TEST_ENABLED: boolean = false;
  // public static SKIP_REGISTRATION: boolean = Test.TEST_ENABLED;
  public static readonly ALWAYS_LOGGED: boolean = Test.TEST_ENABLED;

  // TEST CASSETTE
  public static readonly TEST_SOCIAL: boolean = false;
  public static readonly TEST_ACCOUNT: boolean = false;
  public static readonly TEST_LOYALTY: boolean = false;
  public static readonly TEST_ECOMMERCE: boolean = false;
  public static readonly TEST_RIGHTS: boolean = false;

  public static readonly TEST_AGGREGATO: boolean = false;
  public static readonly TEST_PUNTEGGIO: boolean = false; // abilita i console log del punteggio

  public static readonly TEST_OFFERS: boolean = false; // se attivo, disabilita la chiamata che registra l'evento click offerta su google analytics

  public static readonly PUNTAMENTO_LOCALE: boolean = false;

}
