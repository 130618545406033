import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationExtras } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Authentication } from '../authentication/authentication.service';
import { EnvironmentService } from '../environment/environment.service';
import { PopupCreateDelegationPage } from '../../pages/popups/popup-create-delegation/popup-create-delegation.page';
import { PopupAcceptedDelegationPage } from "../../pages/popups/popup-accepted-delegation/popup-accepted-delegation.page";
import { PopupRefusedDelegationPage } from "../../pages/popups/popup-refused-delegation/popup-refused-delegation.page";
import { PopupUserHasNoDocumentPage } from "../../pages/popups/popup-user-has-no-document/popup-user-has-no-document.page";


@Injectable()
export class DelegationService {

  constructor(private auth: Authentication, private env: EnvironmentService,
              public httpClient: HttpClient, private modalCtrl:ModalController,
              public navCtrl: NavController){
  }

  // OPERATIONS

  getDelegationVault(request_type,vault): Observable<any> {
      let headers = {
        'Authorization': 'Token '+ this.auth.getAccessToken()
      };
      let params = {
        "delegation_state": request_type,
        "vault": vault
      }
      return this.httpClient.get(this.env.DELEGATIONS, {params:params,headers: headers,responseType:'blob'});
    }

  alterDelegationVault(state,vault): Observable<any> {
      let headers = {
        'Accept': 'application/json',
        'Authorization': 'Token '+ this.auth.getAccessToken()
      };
      let params = {
        "delegation_state": state,
        "vault": vault
      }
      return this.httpClient.put(this.env.DELEGATIONS, params,{headers: headers});
    }

  deleteDelegationVault(vault): Observable<any> {
      let headers = {
        'Accept': 'application/json',
        'Authorization': 'Token '+ this.auth.getAccessToken()
      };
      let params = {
        "delegation_state": "DELETED",
        "vault": vault
      }
      return this.httpClient.delete(this.env.DELEGATIONS, {params:params, headers: headers});
    }

  //POPUPS

  async showDelegationPopup(cassetta) {
    let modal = await this.modalCtrl.create({
      component: PopupCreateDelegationPage,
      componentProps: {
        cassetta: cassetta
      },
      cssClass: ['popup-delegations'],
      backdropDismiss: false
    });
    await modal.present()
    modal.onDidDismiss().then(data => {
      if (data.data.creation) {
        this.auth.getProfile().subscribe({next: (userinfo) => {
          if (userinfo.has_document) {
            this.DelegationTrue(cassetta);
          } else {
            this.openPopupHasNoDocument(cassetta);
          }
        },error: (err) => {
          console.error("error in checking"+ err);
        }});
      } else {
        this.DelegationFalse(cassetta)
      }
    });
  }

  async DelegationTrue(cassetta){
    let modalTrue = await this.modalCtrl.create({
      component: PopupAcceptedDelegationPage,
      componentProps: {
        cassetta: cassetta
      },
      cssClass: ['popup-delegations'],
      backdropDismiss: false
      });
    await modalTrue.present();
  }

  async openPopupHasNoDocument(cassetta) {
    let popup = await this.modalCtrl.create({
      component: PopupUserHasNoDocumentPage,
      componentProps: {
        hasCassette: true,
        cassetta: cassetta,
        fromDelegation: true,
        fromRights: false
      },
      cssClass: ['popup-delegations'],
      backdropDismiss: false
    });
    await popup.present();
    popup.onDidDismiss().then(data => {
      let goToUpload = data.data.goToUpload;
      if (goToUpload != null) {
        if (goToUpload) {
          let navextra:NavigationExtras = {
            state: {"fromPage":'delegation'}
          }
          this.navCtrl.navigateForward('document', navextra);
        }
      }
    });
  }

  async DelegationFalse(cassetta){
    let modalTrue = await this.modalCtrl.create({
      component: PopupRefusedDelegationPage,
      componentProps: {
        cassetta: cassetta
      },
      cssClass: ['popup-delegations'],
      backdropDismiss: false
    });
    await modalTrue.present();
  }
}



