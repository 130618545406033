import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AlertController } from '@ionic/angular';
import { Authentication } from "../authentication/authentication.service";
import { MyPlatformService} from "../my-platform/my-platform.service";
import { PushUtil } from "../push-util/push-util.service";

declare let grecaptcha: any;

@Injectable()
export class FirebaseService {

  //not used
  recaptchaWidgetId: string;

  constructor(public myplt:MyPlatformService,
              private auth: Authentication, private pushUtil: PushUtil,
              public alertCtrl: AlertController) {}

  init() {
    if (this.myplt.isAndroid) {
      (<any>window).FirebasePlugin.getToken(token => {
        // save this server-side and use it to push notifications to this device
        this.savePushToken(token);
      }, error => {
        console.error('NOTIFICATION PUSH error: ' + error);
      });
      (<any>window).FirebasePlugin.onTokenRefresh(token => {
        // save this server-side and use it to push notifications to this device
        this.savePushToken(token)
      }, error => {
        console.error(error);
      });
    } else if (this.myplt.isIos){
      (<any>window).FirebasePlugin.getTokenAPNs(token => {
        // save this server-side and use it to push notifications to this device
        this.savePushToken(token);
      }, error => {
        console.error('NOTIFICATION PUSH error: ' + error);
      });
      (<any>window).FirebasePlugin.onTokenAPNsRefresh(token => {
        // save this server-side and use it to push notifications to this device
        this.savePushToken(token);
      }, error => {
        console.error('NOTIFICATION PUSH error: ' + error);
      });
    }
  }

  registerForPush(onNotificationCallback) {
    (<any>window).FirebasePlugin.onMessageReceived(
      (notification: any) => {
        this.handleNotification(notification, onNotificationCallback);
      },
      error => console.error('NOTIFICATION PUSH error: ',error));
  }

  private handleNotification(notification: any, onNotificationCallback) {
    setTimeout(() => {
      if (onNotificationCallback) {
        onNotificationCallback(notification);

      }
    }, 500);
  }

  /**
   *
   * @param successCallback
   */
  initWebRecaptcha(successCallback) {
    let firebaseAuth = (<any>window).firebase.auth;
    (<any>window).recaptchaVerifier = new firebaseAuth.RecaptchaVerifier('submitter-button', {
      'size': 'invisible',
      'callback': successCallback
    });
    let controller = this;
    (<any>window).recaptchaVerifier.render().then(function (widgetId) {
      controller.recaptchaWidgetId = widgetId;
    });
  }

  /**
   * STEP 1 - Metodo per la verifica del numero di telefono. Viene inviato l'SMS al numero inserito dall'utente
   * e restituito un verificationID in risposta da firebase
   * @param phoneNumber
   * @param successCallBack
   * @param errorCallback
   */
  verifyPhoneNumber(phoneNumber:string, successCallBack, errorCallback) {
    console.log("verifying number... ", phoneNumber);
      let controller = this;
      let appVerifier = (<any>window).recaptchaVerifier;
      console.log("signing phone");
      (<any>window).firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function (confirmationResult) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          console.log("result: ", confirmationResult);
          (<any>window).confirmationResult = confirmationResult;
          let id = confirmationResult.verificationId;
          if (successCallBack) {
            successCallBack(id);
          }
          grecaptcha.reset(controller.recaptchaWidgetId);
        })
        .catch(function (error) {
          console.error('caught error in signInWithPhoneNumber', error);
          if (errorCallback) {
            console.error('error');
            errorCallback(error);
          }
          grecaptcha.reset(controller.recaptchaWidgetId);
        });
        // TO DO RECOVER IOS CODE
  }

  /**
   * STEP 2 - Metodo per la conferma del codice ricevuto
   * @param verificationId
   * @param code
   * @param successCallBack
   * @param errorCallback
   */
  signInWithCredential(verificationId, code, successCallBack, errorCallback) {
        /*
     We use firebase.js (imported from index.html) to do the final step
     of phone auth
     */
    console.log("signInWithCredential", verificationId);
    let credential = (<any>window).firebase.auth.PhoneAuthProvider.credential(verificationId, code);
    // //Then, you can sign in the user with the credential:
    (<any>window).firebase.auth().signInWithCredential(credential)
      .then(result => {
        if (successCallBack) {
          successCallBack(result);
        }
      }).catch(error => {
        if (errorCallback) {
          errorCallback(error);
        }
      });
  }

  unregister(): Observable<any> {
    const unregisterObservable = new Observable((observer) => {
      try {
        (<any>window).FirebasePlugin.unregister();
        observer.next();
        observer.complete();
      } catch (error) {
        console.debug("error from service");
        console.debug(error);
        observer.error(error);
        observer.complete();
      }
    });
    return unregisterObservable;
  }

  private savePushToken(token: string) {
    this.auth.setPushToken(token);
  }

  registerAPNs() {
    if (this.myplt.isIos) {
      (<any>window).FirebasePlugin.registerForRemoteNotificationsAPNs();
    }
  }

  unregisterAPNs() {
    if (this.myplt.isIos) {
      (<any>window).FirebasePlugin.unregisterAPNs();
    }
  }

  getTokenAPNs() {
    if (this.myplt.isIos) {
      (<any>window).FirebasePlugin.getTokenAPNs(token => {
        this.savePushToken(token);
      }, error => {
        console.error('NOTIFICATION APNs PUSH error: ' + error);
      });
    }
  }

  onTokenAPNsRefresh() {
    if (this.myplt.isIos) {
      (<any>window).FirebasePlugin.onTokenAPNsRefresh(token => {
        this.savePushToken(token);
      }, error => {
        console.error('NOTIFICATION APNs PUSH error: ' + error);
      });
    }
  }

  async simpleAlert(title: string, subtitle: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: subtitle,
      buttons: ['Ok']
    });
    await alert.present();
  }

}
