import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Province } from "../../models/province";
import { EnvironmentService } from '../environment/environment.service';

@Injectable()
export class ProvinceService {

  constructor(private env: EnvironmentService, public httpClient: HttpClient){
  }

  getProvince(): Observable<any> {
      let headers = {
        'Accept': 'application/json',
      };
      return this.httpClient.get<Province>(this.env.PROVINCE, {headers: headers});
    }

}
