import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Cassetta } from '../../../models/cassetta/cassetta.model';

@Component({
  selector: 'app-popup-accepted-delegation',
  templateUrl: './popup-accepted-delegation.page.html',
  styleUrls: ['./popup-accepted-delegation.page.scss'],
})
export class PopupAcceptedDelegationPage  {

  cassetta: Cassetta;

  constructor(public modalCtrl: ModalController) { }

  closeModal() {
    this.modalCtrl.dismiss();
  }


}
