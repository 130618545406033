<ion-content class="ion-text-center">
  <div class="ion-margin-left ion-margin-right">
    <img class="top-image"
          *ngIf="!fromDelegation"
          src="assets/imgs/icon_modal/icon_modal_weople_logo@3x.png"
          alt="logo">
     <div class="colorFromTypeCode {{cassetta.vTypeCode}} {{cassetta.vCategory}}"
          *ngIf="fromDelegation">
        <span class="top-image-cassetta logo-img"></span>
      </div>
    <div class="divider ion-align-item-center"></div>
    <p class="text" *ngIf="hasCassette && !fromRights&&!fromDelegation">
      Sblocca i Wecoin ancora in attesa! Come?
    </p>
    <p class="text" *ngIf="!fromRights&&!fromDelegation">
      Per ottenere copia dei tuoi dati dalle aziende e guadagnarci anche tu,
      devi semplicemente dimostrare loro chi sei:
      carica la foto di un tuo documento di identità nel tuo profilo.
    </p>
    <p class="text" *ngIf="fromRights">
      Anche per ottenere dalle aziende il blocco del trasferimento
      dei tuoi dati a terzi, devi semplicemente dimostrare loro chi sei:
      carica la foto di un tuo documento di identità nel tuo profilo.
    </p>
    <div *ngIf="fromDelegation">
      <p class="popup-subtitle"> Complimenti </p>
      <p class="text">
        Hai attivato la cassetta di sicurezza<span class="text-color"> {{cassetta.vType}} </span>
        e delegato <span class="text evidence"> Weople </span>a chiedere una copia dei tuoi dati.
      </p>
      <p class="text">
        Manca un ultimo passaggio: caricare un documento d'identità.
        Le aziende, per nostra esperienza, ne richiedono una copia.
      </p>
      <p class="text">
        Weople non invierà il tuo documento d'identità a
        <span class="text-color"> {{cassetta.vType}} </span>,
        ma lo mostrerà in modalità protetta con il solo scopo di validare la delega.
      </p>
    </div>
  </div>
  <ion-grid>
    <ion-row>
      <ion-col size="3"></ion-col>
      <ion-col size="6">
        <ion-button color="primary" expand="block" mode="ios" (click)="closeModal(true)">
          CARICA  DOCUMENTO <br> D'IDENTITÀ
        </ion-button>
      </ion-col>
      <ion-col size="3"></ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="3"></ion-col>
      <ion-col size="6">
        <ion-button color="light" expand="block" mode="ios" (click)="closeModal(false)">
          CHIUDI
        </ion-button>
      </ion-col>
      <ion-col size="3"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
