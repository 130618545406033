<div class="ion-padding  ion-text-center">
  <ion-icon name="close-outline" (click)="closeModal()" class="btn-close"></ion-icon>
  <img class="top-image" src="assets/imgs/icon_gain_relax/icon_gain_relax_salvadanaio_color@3x.png"
    alt="piggybank logo">
  <p class="popup-subtitle">Il salvadanaio personale</p>
  <hr class="divider">
</div>

<ion-content class="ion-text-center ion-padding-left ion-padding-right ion-scroll scroll-content">
  <p class="text">
    In questo salvadanaio Weople verserà, onorando il nostro patto,
    quanto le aziende pagheranno per mandarti una pubblicità e/o un'offerta di prodotti o servizi
    personalizzati (che potrai visualizzare cliccando il bottone delle offerte personalizzate).
  </p>
  <p class="text">
    Weople ti manderà una notifica per avvisarti che ci sono opportunità per te.
    Ma attenzione: non hai assolutamente nessun obbligo di guardare una pubblicità,
    un'offerta o di comperare qualcosa.
  </p>
  <p class="text">
    Il valore in Euro ti verrà messo a disposizione tramite sistemi di pagamento digitali
    e potrai usarlo come vorrai.
    <br>
    Per saperne di più -
    <b> <ion-text color="primary" (click)="goToLinkMoreInfo()"><u>Guida</u></ion-text></b>
  </p>
</ion-content>
<ion-item mode="ios" lines="none" slot="fixed">
  <ion-checkbox mode="md" slot="end" [(ngModel)]="dontshowagain">Non mostrare più</ion-checkbox>
</ion-item>