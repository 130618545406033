<div class="ion-padding-left ion-padding-right ion-text-center">
  <ion-icon name="close-outline" (click)="closeModal()" class="btn-close"></ion-icon>
  <img class="top-image" src="assets/imgs/icon_gain_relax/icon_gain_relax_vincite_color@3x.png" alt="">
  <p class="popup-subtitle">Vincite</p>
</div>
<ion-content class=" ion-text-center ion-padding-left ion-padding-right ion-scroll scroll-content" >
  <span class="text">
      Questa è l'area delle vincite con estrazione.
      <br><br>
      Qui Weople produrrà valore aggiuntivo per te seguendo un' altra strada:
      investirà masse di dati, anonimi e protetti, nel mercato,
      cercando di generare più valore economico possibile.
      <br><br>
      A differenza del salvadanaio, dove l' offerta dell' azienda è riconducibile
      ad una persona in particolare, qui il valore viene generato
      dall' insieme dei dati. E l' unione fa la forza.
      <br><br>
      Quindi, il valore generato dall'insieme verrà utilizzato per comperare premi
      da distribuire al maggior numero di persone possibili tramite più estrazioni durante l'anno.
      <br><br>
      Per saperne di più -
      <b>
        <ion-text color="primary" (click)="goToLinkMoreInfo()"><u>Guida</u></ion-text>
      </b>
      <br>
      Per consultare il regolamento -
       <b>
          <ion-text color="primary" (click)="goToPageRegolamento()"><u>Guida</u> </ion-text>
      </b>
  </span>
</ion-content>
  <ion-item lines="none" mode="ios" slot="fixed">
    <ion-checkbox mode="md" slot="end" [(ngModel)]="dontshowagain"> Non mostrare più </ion-checkbox>
  </ion-item>

