<div class="ion-padding  ion-text-center">
  <div class="colorFromTypeCode {{cassetta.vTypeCode}} {{cassetta.vCategory|lowercase}}">
    <span class="top-image-cassetta logo-img"></span>
  </div>
  <hr class="divider">
</div>
<ion-content class="ion-text-center ion-padding-left ion-padding-right ion-scroll scroll-content">
  <p class="text">
    Hai attivato la cassetta di sicurezza <span class="text-color"> {{cassetta.vType}} </span>
     senza delegare<span class="text evidence"> Weople </span>
  </p>
  <p class="text">
    Se cambi idea, nella sezione <span class="text evidence">Deleghe</span>
    sarai in grado di creare la delega, così da ricevere una copia dei tuoi dati e investirli
  </p>
  <div class="container-fixed-bottom buttons">
    <ion-grid>
      <ion-row size="12">
        <ion-col size="4">
        </ion-col>
        <ion-col size="4">
          <ion-button color="primary" expand="block" mode="ios" (click)="closeModal()">
            CHIUDI
          </ion-button>
        </ion-col>
        <ion-col size="4">
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>