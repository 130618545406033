import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Preferences } from '@capacitor/preferences';

/**
 * Servizio di storage che centralizza le funzionalità di storage dell'app. In base alla platform
 * viene utilizzato il native storage o il local storage.
 * In più fornisce anche per il local storage un metodo che torna una promise cosi da avere lo stesso comportamento su
 * tutte le piattaforme.
 */

@Injectable()
export class AppStorage {

  static instance: AppStorage;
  static readonly USER_INFO: string = 'userInfo';
  static readonly AGGREGATO: string = 'aggregato';
  static readonly MODALI_VIEW: string = 'modali_view';
  static readonly CATEGORY_ADDED: string = 'category_added';
  static readonly PASSPHASE_KEY: string = 'passphrase_key';
  static readonly PUBLIC_KEY: string = 'public_key';
  static readonly PRIVATE_KEY: string = 'private_key';
  static readonly NO_KEYPAIRS_SAVED: string = 'no_keypairs_saved';

  constructor( public platform: Platform) {
    AppStorage.instance = AppStorage.instance || this;
  }

  async getItem(property: string): Promise<any> {
      console.debug("AppStorage->getItem:", property);
      let { value } = await Preferences.get({ key: property });
      if (value != null && value != undefined) {
        if (typeof value == "string") {
          try {
            value = JSON.parse(value);
          } catch (error) { }
        }
      }
      return value;
  }

  async setItem(property: string, value: any): Promise<any> {
      /* Poichè il localstorage web come valore prevede
      solo le stringhe convertiamo in stringa se necessario */
      console.debug("AppStorage->setItem", property);
      let localValue = value;
      console.debug(localValue);
      if (typeof localValue != "string") {
        localValue = JSON.stringify(localValue);
      }
      return await Preferences.set({
        key: property,
        value: localValue
      });
  }

  async remove(property: string): Promise<any> {
    return await Preferences.remove({ key: property });
  }

  async clearAll(): Promise<any> {
    return await Preferences.clear();
  }
}