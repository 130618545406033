import { Observable, throwError, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertController} from '@ionic/angular';
import { AppComponent } from "../../app.component";
import { Authentication } from "../authentication/authentication.service";
import { EventsService } from "../events/events.service";
import { SpinnerService } from "../spinner/spinner.service";

export interface Error {
  code: number,
  message: string,
  result: number,
  name: string
}

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private alertCtrl: AlertController, private auth: Authentication,
              private events: EventsService, private router:Router,
              private spinnerService: SpinnerService,){}

  title: string = 'Errore';

  presentMessage(message) {
    if (message == null) {
      return;
    }
    this.presentAlert(this.title, message);
  }

  async presentAlert(title, message) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: message,
      buttons: ['Chiudi']
    });
    await alert.present();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // quando l'utente ha gia' clickato su una offer non deve andare in errore
    let respondOfferRegex = /offers\/\d+\/respond/gmi;
    if (respondOfferRegex.test(req.url)) {
      this.spinnerService.stop();
      return next.handle(req)
    }
    let show: boolean = this.spinnerService.showLoader;
    if (req.url.indexOf('/userdata/') !== -1) {
      show = false;
    }
    if (show) this.spinnerService.start();
    return next.handle(req)
        .pipe(tap(evt => { //send the request
          if (evt instanceof HttpResponse) {
            this.spinnerService.stop();
          }
        }), catchError((errorObj: HttpErrorResponse) => {
          console.error("CustomHttpInterceptor Response Error:", errorObj);
          const managedError: Error = errorObj.error;
          // gestiamo l'errore se sono valorizzate le proprietà error.code e error.result
          let message: string="";
          if (managedError.code != null) {
            if (managedError.code == 4224){
              message = "Errore di Validazione. Controlla i dati inseriti"
            }
            else{
              message = managedError.message;
            }
          } else if (errorObj.status != 200) {
              switch (errorObj.status) {
                case 401:{
                  message="Sessione Scaduta"
                  this.spinnerService.stop();
                  this.auth.logout().subscribe(() => {
                      this.events.publish(AppComponent.INVALID_TOKEN_EVENT, {});
                      this.router.navigateByUrl('/registrati-o-accedi')
                    });
                  break;
                }
                case 404:{
                  message = 'Pagina non trovata'
                  break;
                }
                case 500:{
                  message = 'Errore del server'
                  break;
                }
                case 501:{
                  message = 'Il numero di questa tessera fedeltà è già presente tra quelle attivate nel tuo caveau personale, potresti verificare di non averla già inserita?';
                  break;
                }
                case 0:{
                  break;
                }
                default:{
                  message = errorObj.error.message;
                  break;
                }
              }
          }
        console.error("ERROR:",message);
        this.spinnerService.stop();
        this.presentMessage(message);

        return throwError(()=>errorObj);
      }));
  }
}
