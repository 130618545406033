<ion-content class="ion-padding-left ion-padding-right ion-text-center">
  <div class="vertical-center-container container-modal">
    <div class="center-column">
      <div *ngIf="progress.state === 'init'">
        <p class="text">Inizializzazione...</p>
      </div>
      <div *ngIf="progress.state === 'keys'">
        <p class="text">
          E' in corso la creazione di una coppia di CHIAVI per la trasmissione sicura dei dati
        </p>
      </div>
      <div *ngIf="progress.state === 'session'">
        <p class="text">
          E' in corso la creazione di una SESSIONE sicura
        </p>
      </div>
      <div *ngIf="progress.state === 'upload'">
        <p class="text">
          E' in corso l'INVIO DEL FILE con i tuoi dati.
          <span *ngIf="isWeb">Non ricaricare la pagina fino al completamento</span>
          <span *ngIf="!isWeb">Non chiudere l'app fino al completamento</span>
        </p>
         <div class="progress-bar">
          <img src="assets/imgs/animation/827.svg" alt="progress-bar">
        </div>
      </div>
    </div>
  </div>
</ion-content>
