import {Injectable} from '@angular/core';

export class PlaceDetail {
  locality: string;
  postal_code: string;
  country: string;
  administrative_area_level_2: string;
  route: string;
  street_number: string

  constructor() {
    this.locality = '';
    this.postal_code = '';
    this.country = '';
    this.administrative_area_level_2 = '';
    this.route = '';
    this.street_number = '';
  }
}

@Injectable()
export class GeoAutocompleteService {

  formatPlaceDetail(placeDetail): PlaceDetail {
    if (!placeDetail) return;
    const formattedPlaceDetail = new PlaceDetail();
    for (let elem of placeDetail) {
      switch (elem.types[0]) {
        case 'route':
          formattedPlaceDetail.route = elem.long_name;
          break;
        case 'street_number':
          formattedPlaceDetail.street_number = elem.long_name;
          break;
        case 'locality':
          formattedPlaceDetail.locality = elem.short_name;
          break;
        case 'administrative_area_level_2':
          formattedPlaceDetail.administrative_area_level_2 = elem.short_name;
          break;
        case 'postal_code':
          formattedPlaceDetail.postal_code = elem.short_name;
          break;
        case 'country':
          formattedPlaceDetail.country = elem.long_name;
          break;

        default:
          break;
      }
    }
    return formattedPlaceDetail;
  }

  fillInAddress(autocomplete): PlaceDetail {
    let placeDetails = {}
    if (typeof autocomplete != 'undefined') {
      let placeDetails = autocomplete.getPlace().address_components;
    return this.formatPlaceDetail(placeDetails)
    }
  }
}

