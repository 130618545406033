import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/registrati-o-accedi/registrati-o-accedi.module').then( m => m.RegistratiOAccediPageModule)
  },
  {
    path: 'registrati-o-accedi',
    loadChildren: () => import('./pages/registrati-o-accedi/registrati-o-accedi.module').then( m => m.RegistratiOAccediPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'modal-sintesi-contratto',
    loadChildren: () => import('./pages/modal-sintesi-contratto/modal-sintesi-contratto.module').then( m => m.ModalSintesiContrattoPageModule)
  },
  {
    path: 'contract-page',
    loadChildren: () => import('./pages/contract-page/contract-page.module').then( m => m.ContractPageModule)
  },
  {
    path: 'document-suggestion',
    loadChildren: () => import('./pages/document-suggestion/document-suggestion.module').then( m => m.DocumentSuggestionPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'tabs/caveau',
    loadChildren: () => import('./pages/caveau/caveau.module').then( m => m.CaveauPageModule)
  },
  {
    path: 'tabs/rights',
    loadChildren: () => import('./pages/rights/rights.module').then( m => m.RightsPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'confirm-number',
    loadChildren: () => import('./pages/confirm-number/confirm-number.module').then( m => m.ConfirmNumberPageModule)
  },
  {
    path: 'recaptcha',
    loadChildren: () => import('./pages/recaptcha/recaptcha.module').then( m => m.RecaptchaPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'modal-animation',
    loadChildren: () => import('./pages/modal-animation/modal-animation.module').then( m => m.ModalAnimationPageModule)
  },
  {
    path: 'resetPassword',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'popup-livelli',
    loadChildren: () => import('./pages/popups/popup-livelli/popup-livelli.module').then( m => m.PopupLivelliPageModule)
  },
  {
    path: 'popup-caveau',
    loadChildren: () => import('./pages/popups/popup-caveau/popup-caveau.module').then( m => m.PopupCaveauPageModule)
  },
  {
    path: 'tabs/prizes-and-offers',
    loadChildren: () => import('./pages/prizes-and-offers/prizes-and-offers.module').then( m => m.PrizesAndOffersPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPagePageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/profile/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'popup-rights',
    loadChildren: () => import('./pages/popups/popup-rights/popup-rights.module').then(m => m.PopupRightsPageModule)
  },
  {
    path: 'popup-user-has-no-document',
    loadChildren: () => import('./pages/popups/popup-user-has-no-document/popup-user-has-no-document.module').then( m => m.PopupUserHasNoDocumentPageModule)
  },
    {
    path: 'welcome',
    loadChildren: () => import('./pages/survey/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'survey',
    loadChildren: () => import('./pages/survey/survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: 'interests',
    loadChildren: () => import('./pages/interests/interests.module').then( m => m.InterestsPageModule)
  },
  {
    path: 'interests-vault',
    loadChildren: () => import('./pages/vaults/interests-vault/interests-vault.module').then( m => m.InterestsVaultPageModule)
  },
  {
    path: 'survey-vault',
    loadChildren: () => import('./pages/vaults/survey-vault/survey-vault.module').then( m => m.SurveyVaultPageModule)
  },
  {
    path: 'add-account',
    loadChildren: () => import('./pages/vaults/add-account/add-account.module').then( m => m.AddAccountPageModule)
  },
  {
    path: 'add-loyalty',
    loadChildren: () => import('./pages/vaults/add-loyalty/add-loyalty.module').then( m => m.AddLoyaltyPageModule)
  },
  {
    path: 'cassetta-details',
    loadChildren: () => import('./pages/vaults/cassetta-details/cassetta-details.module').then( m => m.CassettaDetailsPageModule)
  },
  {
    path: 'cassette-vault',
    loadChildren: () => import('./pages/vaults/cassette/cassette.module').then( m => m.CassettePageModule)
  },
  {
    path: 'loyalty-details',
    loadChildren: () => import('./pages/vaults/loyalty-details/loyalty-details.module').then( m => m.LoyaltyDetailsPageModule)
  },
  {
    path: 'popup-primo-grant',
    loadChildren: () => import('./pages/popups/popup-primo-grant/popup-primo-grant.module').then( m => m.PopupPrimoGrantPageModule)
  },
  {
    path: 'popup-ultimo-grant',
    loadChildren: () => import('./pages/popups/popup-ultimo-grant/popup-ultimo-grant.module').then( m => m.PopupUltimoGrantPageModule)
  },
  {
    path: 'popup-cassetta',
    loadChildren: () => import('./pages/popups/popup-cassetta/popup-cassetta.module').then( m => m.PopupCassettaPageModule)
  },
  {
    path: 'popup-category-added',
    loadChildren: () => import('./pages/popups/popup-category-added/popup-category-added.module').then( m => m.PopupCategoryAddedPageModule)
  },
  {
    path: 'popup-delete-cassetta',
    loadChildren: () => import('./pages/popups/popup-delete-cassetta/popup-delete-cassetta.module').then( m => m.PopupDeleteCassettaPageModule)
  },
  {
    path: 'popup-got',
    loadChildren: () => import('./pages/popups/popup-got/popup-got.module').then( m => m.PopupGotPageModule)
  },
  {
    path: 'popup-perdita-bonus',
    loadChildren: () => import('./pages/popups/popup-perdita-bonus/popup-perdita-bonus.module').then( m => m.PopupPerditaBonusPageModule)
  },
  {
    path: 'alert-tutorial-upload',
    loadChildren: () => import('./pages/alerts/alert-tutorial-upload/alert-tutorial-upload.module').then( m => m.AlertTutorialUploadPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'salvadanaio',
    loadChildren: () => import('./pages/prizes-and-offers/salvadanaio/salvadanaio.module').then( m => m.SalvadanaioPageModule)
  },
  {
    path: 'custom-offers',
    loadChildren: () => import('./pages/prizes-and-offers/custom-offers/custom-offers.module').then( m => m.CustomOffersPageModule)
  },
  {
    path: 'vincite',
    loadChildren: () => import('./pages/prizes-and-offers/vincite/vincite.module').then( m => m.VincitePageModule)
  },
  {
    path: 'popup-prizes-and-offers',
    loadChildren: () => import('./pages/popups/popup-prizes-and-offers/popup-prizes-and-offers.module').then( m => m.PopupPrizesAndOffersPageModule)
  },
  {
    path: 'popup-vincite',
    loadChildren: () => import('./pages/popups/popup-vincite/popup-vincite.module').then( m => m.PopupVincitePageModule)
  },
  {
    path: 'popup-salvadanaio',
    loadChildren: () => import('./pages/popups/popup-salvadanaio/popup-salvadanaio.module').then( m => m.PopupSalvadanaioPageModule)
  },
  {
    path: 'popup-offerte',
    loadChildren: () => import('./pages/popups/popup-offerte/popup-offerte.module').then( m => m.PopupOffertePageModule)
  },
  {
    path: 'popup-complimenti-offer',
    loadChildren: () => import('./pages/popups/popup-complimenti-offer/popup-complimenti-offer.module').then( m => m.PopupComplimentiOfferPageModule)
  },{
    path: 'upload-dati-archiviati',
    loadChildren: () => import('./pages/upload-dati-archiviati/upload-dati-archiviati.module').then( m => m.UploadDatiArchiviatiPageModule)
  },
  {
    path: 'popup-crea-archivio',
    loadChildren: () => import('./pages/popups/popup-crea-archivio/popup-crea-archivio.module').then( m => m.PopupCreaArchivioPageModule)
  },
  {
    path: 'popup-confirm-upload',
    loadChildren: () => import('./pages/popups/popup-confirm-upload/popup-confirm-upload.module').then( m => m.PopupConfirmUploadPageModule)
  },
  {
    path: 'popup-upload-completed',
    loadChildren: () => import('./pages/popups/popup-upload-completed/popup-upload-completed.module').then( m => m.PopupUploadCompletedPageModule)
  },
  {
    path: 'upload-process-modal',
    loadChildren: () => import('./pages/upload-process-modal/upload-process-modal.module').then( m => m.UploadProcessModalPageModule)
  },
  {
    path: 'primo-step-amazon',
    loadChildren: () => import('./pages/tutorials/primo-step-amazon/primo-step-amazon.module').then( m => m.PrimoStepAmazonPageModule)
  },
  {
    path: 'primo-step-facebook',
    loadChildren: () => import('./pages/tutorials/primo-step-facebook/primo-step-facebook.module').then( m => m.PrimoStepFacebookPageModule)
  },
  {
    path: 'primo-step-google',
    loadChildren: () => import('./pages/tutorials/primo-step-google/primo-step-google.module').then( m => m.PrimoStepGooglePageModule)
  },
  {
    path: 'secondo-step',
    loadChildren: () => import('./pages/tutorials/secondo-step/secondo-step.module').then( m => m.SecondoStepPageModule)
  },
  {
    path: 'secondo-step-amazon',
    loadChildren: () => import('./pages/tutorials/secondo-step-amazon/secondo-step-amazon.module').then( m => m.SecondoStepAmazonPageModule)
  },
  {
    path: 'terzo-step',
    loadChildren: () => import('./pages/tutorials/terzo-step/terzo-step.module').then( m => m.TerzoStepPageModule)
  },
  {
    path: 'document',
    loadChildren: () => import('./pages/document/document/document.module').then( m => m.DocumentPageModule)
  },
  {
    path: 'document-detail',
    loadChildren: () => import('./pages/document/document-detail/document-detail.module').then( m => m.DocumentDetailPageModule)
  },
  {
    path: 'document-suggestion',
    loadChildren: () => import('./pages/document/document-suggestion/document-suggestion.module').then( m => m.DocumentSuggestionPageModule)
  },
  {
    path: 'scan-document',
    loadChildren: () => import('./pages/document/scan-document/scan-document.module').then( m => m.ScanDocumentPageModule)
  },
  {
    path: 'popup-documents',
    loadChildren: () => import('./pages/popups/popup-documents/popup-documents.module').then( m => m.PopupDocumentsPageModule)
  },
  {
    path: 'popup-delete-document',
    loadChildren: () => import('./pages/popups/popup-delete-document/popup-delete-document.module').then( m => m.PopupDeleteDocumentPageModule)
  },
  {
    path: 'popup-exit-document',
    loadChildren: () => import('./pages/popups/popup-exit-document/popup-exit-document.module').then( m => m.PopupExitDocumentPageModule)
  },
  {
    path: 'popup-document-added',
    loadChildren: () => import('./pages/popups/popup-document-added/popup-document-added.module').then( m => m.PopupDocumentAddedPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/prizes-and-offers/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'upload-dati-google',
    loadChildren: () => import('./pages/upload-dati-google/upload-dati-google.module').then( m => m.UploadDatiGooglePageModule)
  },
  {
    path: 'manual-guide',
    loadChildren: () => import('./pages/upload-dati-google/manual-guide/manual-guide.module').then( m => m.ManualGuidePageModule)
  },
  {
    path: 'upload-dati-amazon',
    loadChildren: () => import('./pages/upload-dati-amazon/upload-dati-amazon.module').then( m => m.UploadDatiAmazonPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
