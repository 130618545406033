import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Cassetta } from '../../../models/cassetta/cassetta.model';

@Component({
  selector: 'app-popup-refused-delegation',
  templateUrl: './popup-refused-delegation.page.html',
  styleUrls: ['./popup-refused-delegation.page.scss'],
})
export class PopupRefusedDelegationPage {

  cassetta: Cassetta;

  constructor(public modalCtrl: ModalController) { }

  closeModal() {
    this.modalCtrl.dismiss();
  }


}
