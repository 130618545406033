import { Observable, delay } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Injectable, Injector } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { OfferWrapper } from "../../models/offer.model";
import { Authentication } from "../authentication/authentication.service";
import { EnvironmentService } from '../environment/environment.service';



@Injectable()
export class OfferService {

  auth: Authentication;
  constructor(public httpClient: HttpClient, private env: EnvironmentService, public injector: Injector) {
    setTimeout(() => this.auth = injector.get(Authentication));
  }

  getOffers(category?: string): Observable<OfferWrapper> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let params = {'category':category};
    return this.httpClient.get<OfferWrapper>(this.env.OFFERS, { headers: headers, params: params });
  }

  respondToAnOffer(id) {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken(),
    };
    let params={
      id:id
    };
    return this.httpClient.post(this.env.OFFERS + '/respond', null, { headers: headers, params: params });
  }

  doLikeOffer(offerId) {

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken(),
      'content-type': 'multipart/form-data;'
    };

    let params = {
      "activity[sl_type]": "weoplelike",
      "activity[clicktype]": "like",
      "activity[objecttype]": 'offer',
      "activity[objectid]": offerId
    };

    let url = this.env.LIKE_CUSTOM_OFFERS;
    return this.httpClient.post(url, null, { headers: headers, params: params });
  }

  setDate(){
    let date = new Date()
    date.setMonth(date.getMonth() -3);
    let monthLater = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
    return monthLater;
  }

  getLikeOffer(): Observable<any> {
    this.setDate();
    console.log("this.setDate",this.setDate())
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let params = {
      "activity_types": "weoplelike",
      "period_start": this.setDate()
    };
    let url = this.env.GET_LIKE_CUSTOM_OFFERS;
    return this.httpClient.get(url, { headers: headers, params: params });
  }

  doActivityShareOffer(offerId, shareType, channel) {

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken(),
      'content-type': 'multipart/form-data;'
    };

    let params = {
      "activity[sl_type]": "weopleshare",
      "activity[objecttype]": 'offer',
      "activity[objectid]": offerId,
      "activity[sharetype]": shareType,
      "activity[channel]": channel

    };

    let url = this.env.LIKE_CUSTOM_OFFERS;
    return this.httpClient.post(url, null, { headers: headers, params: params });
  }

  getOfferteResp(id): Observable<any> {

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let url = this.env.OFFERS+"/respond";
    return this.httpClient.post(url, null, { headers: headers }).pipe(delay(700))

    .pipe(
      //ATTIVARE IL DELAY SE VOGLIAMO CHE PASSINO N SECONDI PRIMA CHE FACCIA LA CHIAMATA GETRESPONSES,
      switchMap(response => {
        return this.getResponses();
      })
    );
  }

// .pipe(
//     delay(1000),
//   switchMap(response => {
//   return this.getResponses();
// })
// );

  getResponses(): Observable<any>{

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let params = {
      "html": "true",
      "layout": "medium_rectangle",
      "kusable": "true",
      'processing_status': 'pending',
      'per_page': "50",
      'page': "1"
    };


    let url = this.env.OFFERS+"/responses";
    return this.httpClient.get<any>(url, { headers: headers, params: params })
    .pipe(
      map(response => {
        return response.data.responses
      })
    )
  }

}
