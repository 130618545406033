<div class="ion-padding-left ion-padding-right ion-text-center">
  <ion-icon name="close-outline" (click)="closeModal()" class="btn-close"></ion-icon>
  <img class="top-image" src="assets/imgs/icon_gain_relax/icon_gain_relax_offerte_personalizzate_color@3x.png"
    alt="offerte logo">
  <p class="popup-subtitle">Offerte personalizzate</p>
  <hr class="divider">
</div>
<ion-content class="ion-text-center ion-padding-left ion-padding-right ion-scroll scroll-content">
  <p class="text">
    Qui, potrai approfondire a tuo piacimento le offerte personalizzate che le aziende ti invieranno…
    avendoti pagato nel salvadanaio per mandartele.
    Queste offerte verranno veicolate direttamente da Weople a te proteggendo
    rigorosamente il tuo anonimato e il tuo profilo personale.
  </p>
  <p class="text">
    Più dati investi, più sarà facile per Weople proporre
    a tante aziende di mandare offerte a persone come te.
  </p>
  <p class="text">
    Weople manderà una notifica per avvisare che ci sono opportunità per te.
    Ma attenzione: non hai assolutamente nessun obbligo di guardare una pubblicità,
    un'offerta o di comperare qualcosa.<br>
    Per saperne di più -
    <b> <ion-text color="primary" (click)="goToLink()"><u>Guida</u></ion-text></b>
  </p>
</ion-content>
<ion-item lines="none" mode="ios" slot="fixed">
  <ion-checkbox mode="md" slot="end" [(ngModel)]="dontshowagain"> Non mostrare più </ion-checkbox>
</ion-item>