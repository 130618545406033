import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AppStorage } from "../../../services/app-storage/app-storage.service";
import { MyInAppBrowserService } from 'src/app/services/my-in-app-browser/my-in-app-browser.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';


@Component({
  selector: 'app-popup-offerte',
  templateUrl: './popup-offerte.page.html',
  styleUrls: ['./popup-offerte.page.scss'],
})
export class PopupOffertePage {

  dontshowagain: boolean = false;
  popup: string;


  constructor(public navCtrl: NavController,
    public appStorage: AppStorage, public modalCtrl: ModalController,
    private iab: MyInAppBrowserService, private env: EnvironmentService) {
  }

  closeModal() {
    if (this.dontshowagain) {
      this.appStorage.setItem(this.popup, true);
    }
    this.modalCtrl.dismiss();
  }

  goToLink() {
    this.iab.create(this.env.POPUP_OFFERTE_GUIDA_URL, "_blank")
  }

}

