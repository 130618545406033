<div class="ion-padding ion-text-center">
  <div class="colorFromTypeCode {{cassetta.vTypeCode}} {{cassetta.vCategory|lowercase}}">
    <span class="top-image-cassetta logo-img"></span>
  </div>
  <hr class="divider">
  <p class="popup-subtitle"> Complimenti </p>
</div>
<ion-content class="ion-text-center ion-padding-left ion-padding-right ion-scroll scroll-content">
  <p class="text">
    Hai attivato la cassetta di sicurezza <span class="text-color"> {{cassetta.vType}}</span>
  </p>
  <p class="text">
    Ora puoi scegliere se delegare <span class="text evidence"> Weople </span>
    a chiedere una copia dei tuoi dati
  </p>
  <p class="text">
    Senza la delega non potremo inviare la richiesta a tuo nome e i
    <span class="text point"> Wecoin </span> rimarranno in attesa
  </p>
  <button class="bottone-pagina-guida" (click)="showHelp()">Scopri di più sulla delega</button>
  <ion-grid>
    <ion-row>
      <ion-col size="3"></ion-col>
      <ion-col size="6">
        <ion-button color="primary" expand="block" mode="ios" (click)="createDelegation()">
          DELEGA WEOPLE
        </ion-button>
      </ion-col>
      <ion-col size="3"></ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="3"></ion-col>
      <ion-col size="6">
        <ion-button color="light" expand="block" mode="ios" (click)="closeModal()">
          NON DELEGARE
        </ion-button>
      </ion-col>
      <ion-col size="3"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>