<div class="ion-padding  ion-text-center">
  <img class="top-image" src="assets/imgs/icon/deleghe_color.png" alt="logo deleghe">
  <p class="popup-subtitle"> LA DELEGA </p>
</div>
<ion-content class="ion-text-center ion-padding-left ion-padding-right ion-scroll scroll-content">
  <p class="text">
    <span class="text evidence"> Weople </span>
    è un servizio offerto da
    <span class="text evidence">Hoda srl</span>
  </p>
  <p class="text">
    Delegando <span class="text evidence">Hoda</span>,
    verrà creata una delega per ogni singola azienda e della durata di 1 anno.
    Sarà valida unicamente per esercitare il tuo diritto
    alla portabilità dei dati (Art.20 GDPR) e depositarli automaticamente
    <span class="text evidence"> Weople </span> fino a un massimo di 6 volte l'anno
  </p>
  <p class="text">
    La delega verrà operata da <span class="text evidence">Hoda srl</span>
    e nella sezione <span class="text evidence"> Deleghe </span>
    sarai sempre in grado di visualizzarla, sospenderla o cancellarla
  </p>
  <img class="alert-image" src="assets/imgs/icon/alert_color.png" alt="logo alert">
  <p class="text">
    Senza la delega, dovrai contattare tu direttamente
    <span *ngIf="fromConsent"> ogni azienda</span>
    <span *ngIf="!fromConsent" class="text-color"> {{cassetta.vType}} </span>
    per poter investire i tuoi dati in <span class="text evidence"> Weople </span>
  </p>
  <ion-grid>
    <ion-row size="12">
      <ion-col size="4"></ion-col>
      <ion-col size="4">
        <ion-button color="primary" expand="block" mode="ios" (click)="closeModal()">
          INDIETRO
        </ion-button>
      </ion-col>
      <ion-col size="4"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>