import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AggregatoCassette } from '../../models/cassetta/aggregato.cassette.model';
import { CassettaVCategory, Cassetta } from '../../models/cassetta/cassetta.model';
import { Authentication } from "../authentication/authentication.service";
import { CassettaService } from '../cassetta/cassetta.service';
import { EnvironmentService } from '../environment/environment.service';
import { EventsService } from "../events/events.service";
import { RewardService } from '../reward/reward.service';


@Injectable()
export class PunteggioService {
  static readonly instance: PunteggioService;
  private punteggioInAttesa: number = 0;
  private punteggioAcquisito: number = 0;
  public numeroAmici: string;
  private livello = 'Start';
  public accessToken;
  readonly VALORE = {
    social: {
      FB: 0, // ex 5
      IG: 0, // ex 5
      TW: 0, // ex 5
      LI: 0, // ex 5
      grants: 0 // ex 2
    },
    account: {
      GO: 0,
      AP: 0, // ex 10
      grants: 0 // ex 3
    },
    loyalty: {
      CAR: 0, // ex 10
      CON: 0,
      COO: 0,
      ESS: 0,
      GIG: 0,
      IPE: 0,
      UNE: 0,
      AUC: 0,
      IKE: 0,
      DEC: 0,
      MED: 0,
      LTC: 0,
      PAN: 0,
      COI: 0,
      UNI: 0,
      AES: 0,
      FEL: 0,
      PEW: 0,
      CIS: 0,
      MDX: 0,
      ALT: 0, // ex 5
      grants: 0
    },
    ecommerce: {
      ZN: 0, // ex 30
      ALT: 0, // ex 5
      grants: 0
    },
    profilo: {
      profile: 0, // ex 10
      interests: 0, // ex 15
      survey: 0 // ex 15
    },
    referral: {
      // registrazione: 2, //Se mi sono registrato con il codice di un amico, ricevo dei punti
      first: 2, // il numero di punti che viene assegnato da 0 al limite
      others: 2, // il numero di punti che viene assegnato dal limite in poi
      limit: 10 // il numero di amici dopo il quale si cominciano a prendere meno punti
    }
  }
  BONUS = {
    social: 0,
    account: 0,
    loyalty: 0,
    ecommerce: 0
  }
  MASSIMO = {
    social: 107,
    account: 75,
    loyalty: 105,  // ex 70
    ecommerce: 75  // ex 40
  }
  NUMERO_MASSIMO_CASSETTE = {
    loyalty: 8,
    ecommerce: 4
  }
  NUMERO_GRANTS = {
    GO: 0,
    FB: 0,
    ZN: 0,
  }

  static readonly PUNTEGGIO_AGGIORNATO = 'punteggioAggiornato';
  static readonly AGGREGATO_AGGIORNATO = 'aggregatoAggiornato';

  metrics;

  constructor(private auth: Authentication, private cassettaService: CassettaService,
              private env: EnvironmentService, private events: EventsService,
              public httpClient: HttpClient, private rewardService: RewardService){}

  calcoloPunteggio() {
      this.getMetrics().subscribe({next:(res) => {
        if (res.success){
          this.metrics = res.data;
          this.setPunteggioAcquisito(Number(res.data.point.balance));
          this.setPunteggioInAttesa(Number(res.data.pointsnotconfirmed.balance));
          this.setLivello();
        }
      },error:(err) => {
        console.error(err);
      }});
  }

  //Recupero del punteggio acquisito e in attesa dal server
  getMetrics(): Observable<any> {
    //Lo passo la prima volta, e poi lo memorizzo nel file locale per non passarlo ogni volta
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.get<any>(this.env.GET_METRICS, { headers: headers });
  }


  // Massimo 500
  public setLivello() {
    let punteggio = this.getPunteggioAcquisito();
    let livello = 'Start';
    if (punteggio >= 100 && punteggio < 200) {
      livello = 'Bronzo';
    } else if (punteggio >= 200 && punteggio < 300) {
      livello = 'Argento';
    } else if (punteggio >= 300 ) {
      livello = 'Oro';
    }
    this.livello = livello;
    this.events.publish(PunteggioService.PUNTEGGIO_AGGIORNATO);
  }
  getLivello(): string {
    return this.livello;
  }
  getGuadagno(cassetta: Cassetta): number {
    let aggregato = this.cassettaService.getAggregatoInMemory();
    return this.calcoloGuadagno(aggregato, cassetta);
  }
  existGuadagnoFromCategory(category: CassettaVCategory): boolean {
    let aggregato = this.cassettaService.getAggregatoInMemory();
    if (aggregato[category].count >= this.NUMERO_MASSIMO_CASSETTE[category]) {
      return false;
    }
    else {
      return true;
    };
  }
  private calcoloGuadagno(aggregato: AggregatoCassette, cassetta: Cassetta): number {
    let valore = this.VALORE[cassetta.vCategory][cassetta.vTypeCode];
    if (aggregato[cassetta.vCategory].count >= this.NUMERO_MASSIMO_CASSETTE[cassetta.vCategory]) {
      return 0;
    } else return valore;
  }
  getBonus(vCategory: CassettaVCategory) {
    this.getPunteggioFromRewards()
    return this.BONUS[vCategory.toUpperCase()];
  }
  setPunteggioAcquisito(points){
    this.punteggioAcquisito = points;
  }
  getPunteggioAcquisito() {
    return this.punteggioAcquisito;
  }
  setPunteggioInAttesa(points){
    this.punteggioInAttesa = points;
  }

  setNumeroAmici(metric: string): Observable<any>{
    if (this.metrics === undefined) {
      const observable = new Observable((observer) => {
        this.getMetrics().subscribe({next:(r) => {
          this.metrics = r.data;
          this.numeroAmici = this.metrics[metric].balance;
          observer.next(this.numeroAmici);
          observer.complete();
        }, error:(err) =>{
          observer.error(err);
          observer.complete();
        }})
      });
      return observable;
    } else {
      const observable = new Observable((observer) => {
        this.numeroAmici = this.metrics[metric].balance;
        observer.next(this.numeroAmici);
        observer.complete();
      });
      return observable;
    }
  }

  getNumeroAmici(){
    return this.numeroAmici;
  }
  getPunteggioInAttesa() {
    return this.punteggioInAttesa;
  }
  setAccessToken(token){
    this.accessToken = token;
  }
  getPunteggioFromRewards() {
    this.rewardService.getRewards().subscribe({next:(reward) => {
          this.VALORE.social = reward.SOCIAL;
          this.VALORE.account = reward.ACCOUNT;
          this.VALORE.loyalty = reward.LOYALTY;
          this.VALORE.ecommerce = reward.ECOMMERCE;
          this.VALORE.profilo = reward.profilo;
          this.VALORE.referral = reward.referral;
          this.BONUS = reward.bonus;
          this.MASSIMO = reward.massimo;
          this.NUMERO_GRANTS = reward.numero_grants;
          this.VALORE.social.grants = reward.grants.FB;
          this.VALORE.account.grants = reward.grants.GO;
          this.VALORE.ecommerce.grants = reward.grants.ZN;
    }, error:(err) => {
      console.error(err)
    }});
  }
}

