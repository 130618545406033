import { Component} from '@angular/core';
import { ModalController, NavController, } from '@ionic/angular';
import { AppStorage } from '../../../services/app-storage/app-storage.service';
import { UserInfo } from '../../../services/authentication/authentication.service';
import { UserService } from '../../../services/user/user.service';


@Component({
  selector: 'app-popup-delete-user',
  templateUrl: './popup-delete-user.page.html',
  styleUrls: ['./popup-delete-user.page.scss'],
})

export class PopupDeleteUserPage {

  userInfo: UserInfo

  constructor(private appStorage: AppStorage, public ModalCtrl: ModalController,
              public navCtrl: NavController, private userService: UserService) { }

  closeModal() {
    this.ModalCtrl.dismiss();
  }

  deleteUser() {
    console.log(this.userInfo)
    this.userService.deleteUser(this.userInfo.member_id).subscribe({ next: (response) => {
      this.appStorage.clearAll().then(() => {
        this.ModalCtrl.dismiss({ confirmDelete: true });
      }, error => {
        console.error(error);
      });

    }, error: (error) => {
      console.error("Error: ", error);
    }});
  }

}
