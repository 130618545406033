import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from "@ionic/angular"
import { DrawerMenuComponent } from './drawer-menu/drawer-menu.component';
import { ScoreBarComponent } from './score-bar/score-bar.component';
import { RouterModule } from '@angular/router';


@NgModule({
	declarations: [
    DrawerMenuComponent,
    ScoreBarComponent,
  ],
	imports: [
	  IonicModule,
    CommonModule,
    RouterModule
  ],
	exports: [
    DrawerMenuComponent,
    ScoreBarComponent,
  ]
})
export class ComponentsModule {}
