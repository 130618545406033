<div class="ion-padding  ion-text-center">
  <div class="colorFromTypeCode {{cassetta.vTypeCode}} {{cassetta.vCategory|lowercase}}">
    <span class="top-image-cassetta logo-img"></span>
  </div>
  <hr class="divider">
  <p class="popup-subtitle"> Complimenti </p>
</div>
<ion-content class="ion-text-center ion-padding-left ion-padding-right ion-scroll scroll-content">
  <p class="text">
    Hai attivato la cassetta di sicurezza <span class="text-color"> {{cassetta.vType}} </span>
    e delegato<span class="text evidence"> Weople </span> a chiedere una copia dei tuoi dati
  </p>
  <p class="text">
    Nella sezione<span class="text evidence"> Deleghe </span>
    sarai sempre in grado di visualizzarla, sospenderla o cancellarla
  </p>
  <p class="text">
    Ti avviseremo appena i dati saranno disponibili,
    accreditandoti i <span class="text point"> Wecoin </span> in attesa
  </p>
  <div class="container-fixed-bottom buttons">
    <ion-grid>
      <ion-row size="12">
        <ion-col size="4">
        </ion-col>
        <ion-col size="4">
          <ion-button color="primary" expand="block" mode="ios" (click)="closeModal()">
            CHIUDI
          </ion-button>
        </ion-col>
        <ion-col size="4">
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>