import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AppStorage } from "../../../services/app-storage/app-storage.service";
import { MyInAppBrowserService } from '../../../services/my-in-app-browser/my-in-app-browser.service';
import { EnvironmentService } from "../../../services/environment/environment.service";

@Component({
  selector: 'app-popup-vincite',
  templateUrl: './popup-vincite.page.html',
})
export class PopupVincitePage  {
  dontshowagain:boolean = false;
  popup: string;


  constructor(public navCtrl: NavController,public appStorage: AppStorage,
              public modalCtrl: ModalController, private env: EnvironmentService,
              private iab: MyInAppBrowserService) {}


  closeModal() {
    if (this.dontshowagain) {
        this.appStorage.setItem(this.popup, true);
      }
    this.modalCtrl.dismiss();
  }

  goToLinkMoreInfo() {
    this.iab.create(this.env.SALVADANAIO_URL, "_blank")
  }

  goToPageRegolamento() {
    this.modalCtrl.dismiss({goToNews:true});
  }

}
