import { Component, Query } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController, Platform} from '@ionic/angular';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { Badge } from '@capawesome/capacitor-badge';
import { ScreenOrientation, OrientationType } from "@capawesome/capacitor-screen-orientation";
import { AndroidFullScreen } from "@awesome-cordova-plugins/android-full-screen";
import { register } from 'swiper/element/bundle';
register();
import { AppStateService } from "./services/app-state/app-state.service";
import { EnvironmentService } from './services/environment/environment.service';
import { EventsService } from './services/events/events.service';
import { FirebaseService } from "./services/firebase/firebase.service";
import { MyPlatformService } from './services/my-platform/my-platform.service';
import { NationService } from './services/nation/nation.service';
import { SpinnerService } from './services/spinner/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  fromIos: boolean = false;
  public static readonly INVALID_TOKEN_EVENT:string = "invalid_token";
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  fileUploadGoogle: string;
  fileUploadFacebook: string;
  fileUploadAmazon: string;
  linkGoogle: string;
  linkFacebook: string;
  linkAmazon: string;

  constructor(private alertCtrl: AlertController, private stateService: AppStateService,
              private events: EventsService, private firebaseService: FirebaseService,
              public http: HttpClient, private idle: Idle, private location: Location,
              private myPlatform:MyPlatformService, private nationService:NationService,
              private spinnerService:SpinnerService, private platform: Platform, private router:Router) {


              idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
              idle.setTimeout(25*60); // how long can they be idle before considered timed out, in seconds
              idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

              // do something when the user becomes idle
              idle.onIdleStart.subscribe(() => {
                this.idleState = "IDLE";
              });
              // do something when the user is no longer idle
              idle.onIdleEnd.subscribe(() => {
                this.idleState = "NOT_IDLE";
                console.debug(`${this.idleState} ${new Date()}`)
                this.countdown = null;
                //cd.detectChanges(); // how do i avoid this kludge?
              });
              // do something when the user has timed out
              idle.onTimeout.subscribe(() => {
                this.idleState = "TIMED_OUT";
                this.router.navigateByUrl('/registrati-o-accedi');
                console.debug(`${this.idleState} ${new Date()}`);
              });
              // do something as the timeout countdown does its thing
              idle.onTimeoutWarning.subscribe(seconds => {
                console.debug(`${this.idleState} ${new Date()}`);
                this.countdown = seconds
              });

    platform.ready().then(() => {
      let timestamp = new Date();
      let config_url = `${EnvironmentService.CONFIG_CONTROL}?v=${timestamp.getTime()}`;
      let headers = new HttpHeaders({
            'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        });
      if (EnvironmentService.BASIC_AUTH != ""){
        headers = new HttpHeaders({
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization':EnvironmentService.BASIC_AUTH
      });
      }
      this.http.get(config_url, { headers: headers }).subscribe({next:(response) => {
        if (this.myPlatform.isApp){
           //CONTROL version
            App.getInfo().then(value => {
              if(value.version < response["version"]){
                  this.UpdateAlert();
              }
          },error => {
              console.log("error in getting version "+ error);
              this.spinnerService.stop();
          });
        }
          //MAINTENANCE_SCREEN
        let maintenaince = response["maintenance"];
        if (maintenaince) {
          this.router.navigateByUrl('/maintenance')
       }
       this.fileUploadGoogle = response["vaults"]["google"]["upload_name"];
       this.fileUploadFacebook = response["vaults"]["facebook"]["upload_name"];
       this.fileUploadAmazon = response["vaults"]["amazon"]["upload_name"];
       this.linkGoogle = response ["vaults"]["google"]["link_parameters"];
       this.linkFacebook = response ["vaults"]["facebook"]["link_parameters"];
       this.linkAmazon= response ["vaults"]["amazon"]["link_parameters"];
       this.spinnerService.stopWrapper();
       this.spinnerService.forceEnd();
      },error:(error) => {
        console.error("error in getting maintenance status "+ error);
        this.spinnerService.stop();
      }});
      console.debug("-------GetNations---------");
      this.nationService.getNations();
      console.debug("-------SetLocale---------");
      this.setLocale();
      console.debug("-------Redirect---------");
      const currentUrl = this.location.path();
      const queryParams = currentUrl.split('?next=')[1];
      console.debug(queryParams);
      if (queryParams && queryParams.includes("login")) {
        console.debug("Redirecting to login page");
        this.router.navigateByUrl('/login');
      } else if (queryParams && queryParams.includes("registration")) {
        console.debug("Redirecting to registration page");
        this.router.navigateByUrl('/registration');
      }
      console.debug("-------PlatformsCheck---------");
      if(myPlatform.isAndroid && !platform.is("tablet")) {
        console.debug("------------ScreenStuff------------");
          ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
          AndroidFullScreen.isImmersiveModeSupported()
              .then(() => AndroidFullScreen.showSystemUI())
              .catch(console.warn);
          //StatusBar.hide();
      }

      console.debug("-------FireBaseListener---------");
      if(platform.is("android")) {
        this.listenNetworkConnection();
        this.firebaseService.init();
        if (this.platform.is('cordova')) {
          Badge.get().then((data) => {
           this.events.publish(EnvironmentService.ON_BADGE_REFRESH, data.count);
          }).catch(error => {
            console.error(error);
         });
        }
    }
  });
}


  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.reset();
  }

  async UpdateAlert(){
    let alert = await this.alertCtrl.create({
      header: "AGGIORNAMENTO RICHIESTO ",
      message: "Aggiorna l'app per poter accedere al tuo account Weople.",
      backdropDismiss: false,
      buttons: [
        {
          text: 'AGGIORNA',
          handler: () => {
             window.open('market://details?id=digital.hoda.weople', '_system');
             return false;
           }
        },
      ]
    });
    await alert.present();
  }

  menuClosed() {
    this.events.publish("menu:closed", {});
  }

  setLocale() {
    if ((<any>window).Intl && typeof (<any>window) === 'object') {
      let locale = navigator.language.split("-")[1];
      this.stateService.setLocale(locale);
    }
  }

  listenNetworkConnection() {
    Network.addListener("networkStatusChange",result => {
      if(!result.connected) {
        this.networkAlert();
      }
    });
  }

  async networkAlert(){
    let networkAlert = await this.alertCtrl.create({
    header: 'Connessione assente',
    message: 'Nessuna connessione disponibile.',
    buttons: [{
      text: 'Ok',
      handler: () => {
        this.networkAlert = undefined;
        },
      }]
    });
    await networkAlert.present();
  }

  shouldHideTabBar(){
    if (this.myPlatform.isBrowser){
      let elem = <HTMLElement>document.querySelector(".tabbar");
      if (elem != null) {
        elem.style.display = 'none';
      }
    }
  }
}
