import { Injectable } from '@angular/core';
import { LoadingController } from "@ionic/angular";

@Injectable()
export class SpinnerService {

  // In caso di chiamate consecutive, anche su pagine diverse, per evitare l'apertura di più loader
  // utilizzare i metodi 'startWrapper()' e stopWrapper() prima della prima chiamata e al
  // success e l'error dell'ultima.
  //
  // Es.:
  //
  // this.spinnerService.startWrapper()
  // .
  // .
  // this.spinnerService.start()
  // this.spinnerService.stop()
  // .
  // .
  // this.spinnerService.start()
  // this.spinnerService.stop()
  // .
  // .
  // this.spinnerService.stopWrapper()

  private loader:any;
  private loading = 0; // contatore di richieste in attesa di completamento
  public showLoader = true;
  private wrapped = false;
  private stopASAP = false;

  constructor(private loadCtrl: LoadingController) { }

  start() {
    if (this.wrapped) return;
    this.loading++;
    console.debug('ADD LOADING', this.loading)
    this.show();
  }

  stop() {
    if (this.loading > 0) {
      this.loading--;
      this.hide();
      console.debug('REMOVE LOADING', this.loading)
    } else {
      this.loading = 0;
    }
  }

  startWrapper() {
    this.wrapped = true;
    this.show();
  }

  stopWrapper() {
    if (!this.wrapped) return;
    this.loading = 0;
    this.wrapped = false;
    this.hide();
  }

  forceEnd() {
    this.loading = 0;
    if(this.loader != undefined){
      this.hide();
    }else{
      this.stopASAP = true;
    }
  }

  setShowLoader(value: boolean) {
    this.showLoader = value;
  }


  private hasToStart(): boolean {
    if (this.loading == 1 && this.showLoader || this.wrapped && this.showLoader) return true;
    return false
  }

  private hasToStop(): boolean {
    if (this.loading == 0 && this.showLoader) return true;
    return false
  }

  async show() {
    if (this.hasToStart()) {
      console.debug('===>> parte il loader', this.loading)
      this.loader = await this.loadCtrl.create({
        message: 'Caricamento...',
      });
      this.loader.present();
      if(this.stopASAP){
        console.debug('stopASAP', this.hasToStop());
        this.stopASAP = false;
        this.hide();
      }
      console.debug('loader presented', this.stopASAP);
    }
  }

  private async hide() {
    if (this.hasToStop() && this.loader != undefined) {
      console.debug('===>> si ferma il loader', this.loading);
      this.loader.dismiss();
    }else{
      setTimeout(() => { this.hide(); }, 3000);
    }
  }

  private async recursiveHide() {
    const loader = await this.loadCtrl.getTop();
    if (this.hasToStop() && this.loader != undefined) {
      console.debug('===>> si ferma il loader', this.loading);
      this.loader.dismiss();
      console.debug(loader);
      if(loader != undefined){
        setTimeout(() => { this.recursiveHide(); }, 1000);
      }
    }
  }

  isShowing(): boolean{
    if ( this.loading > 0){
      return true;
    }
    else{
      return false;
    }
  }

}
