import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Md5 } from "ts-md5";
import { Injectable, Injector } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Device } from "@capacitor/device";
import { environment } from 'src/environments/environment';
import { Referrals } from "../../models/referral.model";
import { Suggestion } from "../../models/suggestion.model";
import { AppStorage } from "../app-storage/app-storage.service";
import { AppStateService } from "../app-state/app-state.service";
import { CassettaService } from "../cassetta/cassetta.service";
import { EnvironmentService } from '../environment/environment.service'
import { EventsService } from "../events/events.service";
import { MemberPreferenceService } from "../member-preference/member-preference.service";
import { Test } from "../../util/TestUtil";


export class UserInfo {
  integration_id: string;
  aaid: string;
  idfa: string;
  member_id: string;
  email: string;
  first_name: string;
  last_name: string;
  ethnicity: string;
  mailing_street: string;
  mailing_city: string;
  mailing_state: string;
  mailing_postal_code: string;
  mailing_country: string;
  mobile_phone: string;
  lat: number;
  lng: number;
  onboarding_completed: boolean;
  gender: string;
  birthdate: string;
  avatar_url: string;
  prefix: string;
  survey_completed: boolean;
  has_interests: boolean;
  has_document: boolean;
  confirmed_at: string;
  terms_privacy_acceptance: boolean;
  third_party_acceptance: boolean;
  referral_code: Array<string>;
  referrer_id: string;
  email_verified: boolean;
  is_mobile_phone_verified: boolean;
  receive_e_statements: boolean;
  receive_email_offers: boolean;
  receive_mail_offers: boolean;
  receive_mobile_app_offers: boolean;
  receive_newsfeed_comment_notification: boolean;
  receive_newsfeed_like_notification: boolean;
  receive_personalized_offers: boolean;
  receive_sms_offers: boolean;
  visitor: boolean;
  is_gps_permitted: boolean;
  token_id: string;
  token_pass: string;
}

export interface UserData {
  info: UserInfo,
  isLogged: boolean,
  access_token: string,
  refresh_token: string
  created_at: number;
}

@Injectable()
export class Authentication {

  static readonly TOKENS_PROPERTY: string = 'tokens';
  static readonly USER_INFO: string = "userInfo";
  static readonly REMEMBER_ME_PROPERTY: string = 'rememberMe';
  static readonly PUSH_TOKEN_PROPERTY: string = 'pushToken';
  static readonly LOGGED_EMAIL_PROPERTY: string = 'loggedEmail';
  static readonly LOGGED_PWD_PROPERTY: string = 'loggedPwd'
  static readonly REFERRALS: string = 'referrals';
  static readonly SECURE_STORAGE: string = 'secureStorage'
  static instance: Authentication;

  userData: UserData = {
    info: null,
    isLogged: false,
    access_token: null,
    refresh_token: null,
    created_at: null
  };

  pushToken: string;
  auth;
  IDFA_AAID: string = '';

  private userInfo: UserInfo;
  private utm_provider: string;
  private utm_campaign: string;
  private utm_source: string;
  private utm_medium: string;
  private utm_term: string;
  private utm_content: string;
  private utm_ref: string;

  constructor(public httpClient: HttpClient, private env: EnvironmentService,
    public platform: Platform, private appStorage: AppStorage,
    private events: EventsService, private cassettaService: CassettaService,
    private memberPreferenceService: MemberPreferenceService, public injector: Injector,
    private appStateService: AppStateService) {
    setTimeout(() => this.auth = injector.get(Authentication));
    if (Authentication.instance == undefined) {
      appStorage.getItem(Authentication.PUSH_TOKEN_PROPERTY).then(token => {
        this.pushToken = token;
      }, error => {
        console.error(error);
      });
      Authentication.instance = this;
    }
  }

  isLogged() {
    return this.userData.isLogged;
  }

  getAccessToken() {
    return this.userData.access_token;
  }

  getUserData(): UserData {
    return this.userData;
  }

  login(username: string, password: string): Observable<any> {
    return this.loginFormData(username, password);
  }

  /* Metodo utilizzato per l'accesso al sistema. Le operazioni eseguite sono le seguenti
   - login
   - register device
   - getProfile
   */
  loginFormData(username: string, password: string): Observable<any> {
    let body = new FormData();
    body.append('email', username);
    body.append('password', password);
    body.append('client_id', this.getClientID());
    body.append('client_secret', this.getClientSecret());
    body.append('grant_type', 'password');
    let headers = {
      'Accept': 'application/json'
    };
    return this.httpClient.post(this.env.LOGIN, body, { headers: headers })
      .pipe(
        switchMap(response => {
          return this.setLoggedState(response);
        }),
         switchMap(() => {
           return this.registerDevice();
        }),
        switchMap(() => {
          return this.getAndUpdateProfile();
        }),
        switchMap(userInfo => {
          return this.getAggregatoCassette(userInfo);
        }),
        switchMap(userInfo => {
          return this.getMemberPreferences(userInfo);
        }),
      );
  }

  /*
   * Get and update the profile only if idfa/adid wasn't saved
   */
  /*
   * Get and update the profile only if idfa/adid wasn't saved
   */
  private getAndUpdateProfile() {
    return this.getProfile()
      .pipe(
        switchMap(userInfo => {
          let userInfoToUpdate: UserInfo = new UserInfo();

          // Web / Android / iOS
          let integrationId: string = this.generateMd5Hash(userInfo.first_name, userInfo.last_name);
          if (!userInfo.integration_id) {
            userInfoToUpdate.integration_id = integrationId;
          }

          // Android or iOS
          let aaid_idfa: string = userInfo.aaid;
          userInfoToUpdate = this.handleMobileAdsId(userInfoToUpdate, aaid_idfa);

          console.log('AuthenticationService - getAndUpdateProfile - userInfoToUpdate:', userInfoToUpdate);
          return this.updateProfile(userInfoToUpdate).pipe(
            switchMap(() => {
              return of(userInfo);
            })
          );
        })
      );
  }

  private handleMobileAdsId(userInfoToUpdate: UserInfo, aaid_idfa:string): UserInfo{
    let isAndroid = this.platform.is("android");
    let isIOS = this.platform.is("ios");
    if(aaid_idfa && (isAndroid || isIOS)){
      let aaid_idfa_size = aaid_idfa.length;
      if (aaid_idfa_size > 0 && !aaid_idfa.includes(this.IDFA_AAID)) {
        console.log('AuthenticationService - getAndUpdateProfile - aaid_idfa:', aaid_idfa)
        console.log('AuthenticationService - getAndUpdateProfile - IDFA_AAID:', this.IDFA_AAID)
        console.log("auth - getAndUpdateProfile - aaid_idfa_size", aaid_idfa_size)
        if (aaid_idfa_size >= 3) {
          aaid_idfa = aaid_idfa.slice(1);
          console.log("auth - getAndUpdateProfile - aaid_idfa",aaid_idfa);
        }
        this.IDFA_AAID = aaid_idfa + ',' + this.IDFA_AAID;
        if(isAndroid){
          userInfoToUpdate.aaid = this.IDFA_AAID;
        }
        if(isIOS){
          userInfoToUpdate.idfa = this.IDFA_AAID;
        }
      }
    }
    return userInfoToUpdate;
  }


  /* Incapsuliamo il servizio getAggregato in un observer custom per poter ritornare lo userInfo e non
    l'aggregato
   */
    private getAggregatoCassette(response: any): Observable<any> {
      let userInfo: UserInfo = response as UserInfo;
      const aggregatoObservable = new Observable((observer) => {
        this.cassettaService.getAggregato(userInfo.member_id).subscribe({ next: () => {
          observer.next(userInfo);
          observer.complete();
        }, error: (error) => {
          observer.error(error);
          observer.complete();
        }});
      });
      return aggregatoObservable;
    }


  /* Incapsuliamo il servizio getMemberPreferences in un observer custom per poter ritornare lo userInfo e non
  le member preferences
 */
  private getMemberPreferences(response: any): Observable<any> {
    let userInfo: UserInfo = response as UserInfo;
    const memberPrefObservable = new Observable((observer) => {
      if (userInfo.has_interests) {
        this.appStateService.setGainAndRelaxFirstTime(true);
        this.memberPreferenceService.findAll().subscribe({ next: (res) => {
            this.memberPreferenceService.setPreferencesInMemory(res.data);
            observer.next(userInfo);
            observer.complete();
          }, error: (error) => {
            observer.error(error);
            observer.complete();
          }
        });
      } else {
        this.appStateService.setGainAndRelaxFirstTime(true);
        observer.next(userInfo);
        observer.complete();
      }
    });
    return memberPrefObservable;
  }

  private retrieveUserInfoData(response: any): Observable<any> {
    const infoObservable = new Observable((observer) => {
      try {
        let userInfo: UserInfo = response.data;
        observer.next(userInfo);
        observer.complete();
      } catch (error) {
        observer.error(error);
        observer.complete();
      }
    });
    return infoObservable;
  }

  /* Incapsuliamo il servizio getReferrals in un observer custom per poter ritornare lo userInfo e non
  i referrals
 */
  private getReferralsLogin(response: any): Observable<any> {
    let userInfo: UserInfo = response as UserInfo;
    const referralsObservable = new Observable((observer) => {
      this.getReferrals().subscribe({ next: (res) => {
          this.appStorage.setItem(Authentication.REFERRALS, res).then(() => {
            observer.next(userInfo);
            observer.complete();
          }).catch(((err) => {
            observer.error(err);
            observer.complete();
          }))
        }, error: (error) => {
          observer.error(error);
          observer.complete();
        }
      });
    });
    return referralsObservable;
  }

  private saveUserInfoData(userInfo: UserInfo, replaceAll: boolean = false): Observable<any> {
    this.userInfo = userInfo;
    const infoObservable = new Observable((observer) => {
      try {
        if (replaceAll) {
          let userInfoComplete = JSON.parse(JSON.stringify(userInfo));
          /* Rimuoviamo eventuali dati sensibili da non salvare sul dispositivo */
          userInfo = this.deleteUserPrivateData(userInfo);
          this.appStorage.setItem(Authentication.USER_INFO, userInfo).then((data) => {
            this.events.publish(EnvironmentService.ON_USER_REFRESH, data);
            observer.next(userInfoComplete); //userInfo
            observer.complete();
          });
        } else {
          this.appStorage.getItem(Authentication.USER_INFO).then(data => {
            let userInfoPersisted: UserInfo = data as UserInfo;
            /* Copiamo l'oggetto parziale o completo sovrascrivendo le proprietà esistenti in quello persistito */
            userInfoPersisted = Object.assign(userInfoPersisted, userInfo);
            /* La versione completa è usata in memoria ma non persistita */
            let userInfoComplete = JSON.parse(JSON.stringify(userInfoPersisted));
            /* Rimuoviamo eventuali dati sensibili da non salvare sul dispositivo */
            userInfoPersisted = this.deleteUserPrivateData(userInfoPersisted);
            this.appStorage.setItem(Authentication.USER_INFO, userInfoPersisted).then((data) => {
              this.events.publish(EnvironmentService.ON_USER_REFRESH, data);
              observer.next(userInfoComplete); //userInfo
              observer.complete();
            });
          }, error => {
            /* Rimuoviamo eventuali dati sensibili da non salvare sul dispositivo */
            userInfo = this.deleteUserPrivateData(userInfo);
            this.appStorage.setItem(Authentication.USER_INFO, userInfo).then((data) => {
            this.events.publish(EnvironmentService.ON_USER_REFRESH, data);
              observer.next(data);
              observer.complete();
            });
          });
        }
      } catch (error) {
        observer.error(error);
        observer.complete();
      }
    });
    return infoObservable;
  }

  deleteUserPrivateData(userInfo: UserInfo): UserInfo {
    userInfo.mailing_street = '';
    userInfo.mailing_city = '';
    userInfo.mailing_state = '';
    userInfo.mailing_postal_code = '';
    userInfo.mailing_country = '';
    userInfo.prefix = '';
    userInfo.mobile_phone = '';
    userInfo.gender = '';
    userInfo.birthdate = '';
    userInfo.ethnicity = '';
    return userInfo;
  }

  getClientID() {
    if (this.platform.is('android')) {
      return environment.ANDROID_CLIENT_ID;
    } else if (this.platform.is('ios')) {
      return environment.IOS_CLIENT_ID;
    }
    return environment.CLIENT_ID;
  }

  getClientSecret() {
    if (this.platform.is('android')) {
      return environment.ANDROID_CLIENT_SECRET;
    } else if (this.platform.is('ios')) {
      return environment.IOS_CLIENT_SECRET;
    }
    return environment.CLIENT_SECRET;
  }

  refreshAccessToken(): Observable<any> {
    const refreshObservable = new Observable((observer) => {
      this.appStorage.getItem(Authentication.TOKENS_PROPERTY).then(tokens => {
        let refreshToken = tokens.refresh_token;
        /* this code must be here for refresh token to be set */
        const credentials = {
          client_id: this.getClientID(),
          client_secret: this.getClientSecret(),
          grant_type: 'refresh_token',
          refresh_token: refreshToken
        };
        const headers = {
          'Accept': 'application/json'
        };
        return this.httpClient.post(this.env.LOGIN, credentials, { headers: headers }).subscribe({ next: (response) => {
          this.setLoggedState(response).subscribe({next: (r) => {
            observer.next(response);
            observer.complete();
          }, error: (error) => {
            observer.error(error);
            observer.complete();
          }});
        }, error: (error) => {
          observer.error(error);
          observer.complete();
        }});
      }).catch(e => {
        console.debug("Il token non è presente nel local storage", e)
        observer.error(e);
      });
    });
    return refreshObservable;
  }

  signUp(registrationData: any): Observable<any> {
    let credentials = {
      email: registrationData.email,
      password: registrationData.password,
      password_confirmation: registrationData.password_confirmation,
      first_name: registrationData.first_name,
      last_name: registrationData.last_name,
      client_id: environment.CLIENT_ID,
      client_secret: environment.CLIENT_SECRET,
      ethnicity: registrationData.ethnicity,
      mailing_street: registrationData.address,
      mailing_city: registrationData.city,
      mailing_state: registrationData.state,
      mailing_postal_code: registrationData.cap,
      mailing_country: registrationData.country,
      mailing_lat: registrationData.lat,
      mailing_lng: registrationData.lng,
      mobile_phone: registrationData.phone,
      birthdate: registrationData.birthdate,
      gender: registrationData.gender,
      prefix: registrationData.prefix,
      referral_code: registrationData.referral_code,
      terms_privacy_acceptance: registrationData.terms_privacy_acceptance,
      is_referee_a_relative: registrationData.is_referee_a_relative,
      invitation_code: registrationData.invitation_code,
      is_mobile_phone_verified: registrationData.is_mobile_phone_verified,
      utm_campaign: registrationData.utm_campaign,
      utm_provider: registrationData.utm_provider,
      utm_source: registrationData.utm_source,
      utm_medium: registrationData.utm_medium,
      utm_term: registrationData.utm_term,
      utm_content: registrationData.utm_content,
      utm_ref: registrationData.utm_ref,
      integration_id: registrationData.integration_id,
      grant_type: 'password',
    };
    let headers = {
      'Accept': 'application/json'
    };
    let email = credentials.email;
    let password = credentials.password;
    return this.httpClient.post(this.env.REGISTRAZIONE, credentials, { headers: headers })
      .pipe(
        switchMap(() => {
          return this.login(email, password)
        })
      );
  }

  getProfile(token?): Observable<any> {
    let currentToken: string;
    if (token) currentToken = token;
    else currentToken = this.getAccessToken();
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ currentToken
    };
    let replaceAll = true;
    return this.httpClient.get(this.env.GET_USER,{ headers: headers })
      .pipe(
        switchMap(response => {
          return this.retrieveUserInfoData(response);
        }),
        switchMap(response => {
          return this.getReferralsLogin(response);
        }),
        switchMap(userInfo => {
          return this.saveUserInfoData(userInfo, replaceAll);
        })
      );
  }

  checkLogged(): Observable<any> {
    const checkLoggedObservable = new Observable((observer) => {
      this.appStorage.getItem(Authentication.TOKENS_PROPERTY).then(tokens => {
        if (tokens == null) {
          throw new Error("No tokens found");
        }
        this.updateUserData(true, tokens);
        observer.next(tokens);
        observer.complete();
      }).catch(error => {
        observer.error(error);
        observer.complete();
      });
    });
    return checkLoggedObservable;
  }

  updateProfile(profile: UserInfo, local:boolean = false): Observable<UserInfo> {
    return this.updateProfileFormData(profile, local);
  }

  /* Metodo utilizzato per aggiornare (anche parzialmente) il profilo utente
   * A seguito della chiamata di update vengono aggiornati anche i dati locali (storage, menu laterale ecc)
   * */
  updateProfileFormData(profile: UserInfo, local:boolean = false): Observable<any> {
    let body = new FormData();
    let keys = Object.keys(profile);
    for (let key of keys) {
      body.append(key, profile[key]);
    }
    if (local){
      body.append("local","true");
    }
  let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.put(this.env.UPDATE_USER, body, { headers: headers })
      .pipe(
        switchMap(() => {
          return this.saveUserInfoData(profile);
        })
      );
  }

  /* Metodo utilizzato per aggiornare l'intero profilo utente
   * A seguito della chiamata di update vengono aggiornati anche i dati locali (storage, menu laterale ecc)
   * */

  updateRights(profile: UserInfo): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.put(this.env.UPDATE_USER, profile, { headers: headers })
  }

  resetPassword(email: string) {
    let credentials = {
      email: email,
      client_id: this.getClientID(),
      client_secret: this.getClientSecret()
    };
    let headers = {
      'Accept': 'application/json'
    };
    return this.httpClient.post(this.env.FORGOT_PASSWORD, credentials, { headers: headers });
  }

  changePassword(credentials: any) {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.put(this.env.CHANGE_PASSWORD, credentials, { headers: headers });
  }

  confirmEmail(email: string) {
    let credentials = {
      email: email,
      client_id: this.getClientID(),
      client_secret: this.getClientSecret()
    };
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.post(this.env.CONFIRM_EMAIL, credentials, { headers: headers });
  }

  logout(): Observable<any> {
    const logoutObservable = new Observable((observer) => {
      this.appStorage.remove(Authentication.TOKENS_PROPERTY).then(r => {
        this.updateUserData(false);
        this.appStorage.remove(Authentication.USER_INFO)
          .catch(error => {
            observer.error(error);
            observer.complete();
          });
        observer.next();
        observer.complete();
      })
        .catch(error => {
          observer.error(error);
          observer.complete();
        });
    });
    return logoutObservable;
  }

  /* Ad ogni accesso viene richiesto di loggarsi */
  private setLoggedState(loginResponse: any): Observable<any> {
    if (Test.ALWAYS_LOGGED) {
      return this.setTestLoggedState(loginResponse);
    }
    let tokens = {
      access_token: loginResponse.access_token,
      refresh_token: loginResponse.refresh_token,
      created_at: loginResponse.created_at
    };
    const setLoggedObservable = new Observable((observer) => {
       this.updateUserData(true, tokens);
      observer.next();
      observer.complete();
    });
    return setLoggedObservable;
  }

  private setTestLoggedState(loginResponse: any): Observable<any> {
    let tokens = {
      access_token: loginResponse.access_token,
      refresh_token: loginResponse.refresh_token,
      created_at: loginResponse.created_at
    };
    const setLoggedObservable = new Observable((observer) => {
      this.appStorage.setItem(Authentication.TOKENS_PROPERTY, tokens).then(r => {
        this.updateUserData(true, tokens);
        observer.next();
        observer.complete();
      }).catch(error => {
        observer.error(error);
        observer.complete();
      });
    });
    return setLoggedObservable;
  }

  async registerDevice(): Promise<any> {
    let deviceUUID = await Device.getId();
    if (this.pushToken != undefined) {
      console.log('uuid:', deviceUUID);
      console.log('pushToken: ', this.pushToken);
      return await this.registerDeviceWithToken(deviceUUID.identifier, this.pushToken);
    }
    return deviceUUID;
  }

  async registerDeviceWithToken(deviceId: string, token: string): Promise<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let body = new FormData();
    body.append('device_id', deviceId);
    body.append('token', token);
    return this.httpClient.post(this.env.REGISTER_DEVICE, body, { headers: headers });
  }

  private updateUserData(logged: boolean, tokens?: any) {
    if (logged) {
      this.userData.access_token = tokens.access_token;
      this.userData.refresh_token = tokens.refresh_token;
      this.userData.created_at = tokens.created_at;
      this.userData.isLogged = true;
    } else {
      this.userData.access_token = null;
      this.userData.refresh_token = null;
      this.userData.isLogged = false;
      this.userData.created_at = null;
    }
  }

  setPushToken(token: string) {
    if (token == null || token == '' || token == this.pushToken) {
      return;
    }
    this.appStorage.setItem(Authentication.PUSH_TOKEN_PROPERTY, token).then(() => {
      this.pushToken = token;
      if (this.isLogged()) {
        this.registerDevice();
      }
    }, error => {
      console.error(error);
    });
  }

  getSuggestion(type: string, searchTerm: string): Observable<Suggestion[]> {
    let params = {
      type: type,
      term: searchTerm
    };
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.get<Suggestion[]>(this.env.SUGGESTION, { params: params, headers: headers });
  }

  uploadAvatar(file, isBlob: boolean): Observable<any> {
    if (isBlob) {
      file = new File([file], "jpg", { type: 'image/jpeg' })
    }
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken(),
      'Content-Disposition':'form-data; file="avatar"; filename="upload.jpg"'
    };
    let formData: FormData = new FormData();
    formData.append('avatar', file);
    return this.httpClient.post(this.env.UPLOAD_AVATAR, formData, { headers: headers });
  }

  removeAvatar(): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.delete(this.env.UPLOAD_AVATAR, { headers: headers});
  }

  setAdvertisersID(IDFA_AAID: string) {
    this.IDFA_AAID = IDFA_AAID;
  }

  getAdvertisersID(): string {
    return this.IDFA_AAID;
  }

  getReferralCode(): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.get(this.env.REFERRAL_CODE, { headers: headers});
  }

  getReferrals(): Observable<Referrals> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.get<Referrals>(this.env.REFERRALS, { headers: headers });
  }

  getMetrics(): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.get<any>(this.env.GET_METRICS, { headers: headers });
  }

  getUserInfoInMemory(): UserInfo {
    return this.userInfo;
  }

  getUtmProvider(): string {
    return this.utm_provider;
  }

  setUtmProvider(utm_provider: string) {
    this.utm_provider = utm_provider;
  }

  getUtmCampaign(): string {
    return this.utm_campaign;
  }

  setUtmCampaign(utm_campaign: string) {
    this.utm_campaign = utm_campaign;
  }

  getUtmSource(): string {
    return this.utm_source;
  }

  setUtmSource(utm_source: string) {
    this.utm_source = utm_source;
  }

  getUtmMedium(): string {
    return this.utm_medium;
  }

  setUtmMedium(utm_medium: string) {
    this.utm_medium = utm_medium;
  }

  getUtmTerm(): string {
    return this.utm_term;
  }

  setUtmTerm(utm_term: string) {
    this.utm_term = utm_term;
  }

  getUtmContent(): string {
    return this.utm_content;
  }

  setUtmContent(utm_content: string) {
    this.utm_content = utm_content;
  }

  getUtmRef(): string {
    return this.utm_ref;

  }
  setUtmRef(utm_ref: string) {
    this.utm_ref = utm_ref;
  }

  generateMd5Hash(firstName: string, lastName: string): string {
    let timestamp = new Date().getTime();
    let stringToGenerate = firstName + lastName + timestamp;
    try {
      let generatedHash = Md5.hashStr(stringToGenerate);
      return String(generatedHash);
    } catch (exception) {
      return '';
    }
  }

}
