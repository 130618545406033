<ion-content class="menu-slot">
  <ion-list class="profile" [ngClass]="{'extra-margin-toolbar' : platformIsIos}">
    <ion-item  lines="none" class="top-container" (click)="goToProfile()">
      <ion-col size="5" class="ion-no-padding ion-float-left" *ngIf="userInfo != undefined">
        <img class="img-logo rounded-avatar"
            [src]="userInfo.avatar_url"
            *ngIf="userInfo.avatar_url != undefined && userInfo.avatar_url != ''"
            alt="user-avatar"/>
        <div class="placeholder-avatar-container rounded-avatar" *ngIf="userInfo.avatar_url == ''">
          <div class="img-placeholder"></div>
        </div>
      </ion-col>
      <ion-col size="7" class="ion-float-right ion-no-padding" *ngIf="userInfo != undefined">
        <ion-text color="primary" class="user-text ion-text-capitalize">
          <b>{{userInfo.first_name}} {{userInfo.last_name}}</b>
        </ion-text>
        <hr/>
        <score-bar size="small"></score-bar>
      </ion-col>
    </ion-item>
  </ion-list>
  <ion-list *ngFor="let p of pages" class="drawer-menu">
    <ion-item class="menu-element" lines="none" (click)="goTo(p)">
      <ion-icon slot="start" [name]="p.name? p.name: ''"></ion-icon>
      <ion-label>
        {{p.label}}
      </ion-label>
    </ion-item>
  </ion-list>
  <div class="ion-margin-top ion-margin-bottom"></div>
  <ion-list *ngFor="let p of pagesBottom">
    <ion-item class="menu-element"  lines="none" (click)="goTo(p)">
      <ion-icon slot="start" [name]="p.name? p.name: ''"></ion-icon>
      <ion-label>
        {{p.label}}
      </ion-label>
      <span class="rounded-badge" *ngIf="p.pageName=='NotificationsPage' && badgeCount!=0 && badgeCount<=9"><b>{{badgeCount}}</b></span>
      <span class="rounded-badge" *ngIf="p.pageName=='NotificationsPage' && badgeCount>9"><b>9+</b></span>
    </ion-item>
  </ion-list>
</ion-content>
<div class="custom-footer gradient-background" (click)="logout()">
  <ion-icon name="log-out-outline" class="logout-icon ion-float-right"></ion-icon>
  <p class="esci ion-float-right ion-no-margin">Esci<p>
</div>

