import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Cassetta } from '../../../models/cassetta/cassetta.model';

@Component({
  selector: 'app-popup-help-delegation',
  templateUrl: './popup-help-delegation.page.html',
  styleUrls: ['./popup-help-delegation.page.scss'],
})
export class PopupHelpDelegationPage  {

  cassetta: Cassetta;
  fromConsent: boolean;

  constructor(public modalCtrl:ModalController) { }

  closeModal() {
    this.modalCtrl.dismiss();
  }



}
