import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsFeed } from '../../models/feed.model';
import { Authentication } from "../authentication/authentication.service";
import { EnvironmentService } from '../environment/environment.service';


@Injectable()
export class RssService {

  readonly MAX_ARTICLES = 10;

  constructor(public httpClient: HttpClient, private env: EnvironmentService,
              private auth: Authentication) {
  }

  getFeed(name: string): Observable<any> {
   let headers = {
     'Accept': 'application/json',
     'Authorization': 'Token '+ this.auth.getAccessToken()
   };
   let params={
     "interest": name
   };
   return this.httpClient.get<NewsFeed>(this.env.INTERESTS_RSS, { headers: headers, params: params });
 }
}