import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { Nation } from "../../models/nation";

@Injectable()
export class NationService {

  constructor(private env: EnvironmentService, public httpClient: HttpClient){}

  static readonly instance: NationService;
  countriesList = [] ;
  listaNazionalita = [];
  arrayNazioni: Array<Nation> = [];

  getNationalityList(listaNazioni){
    listaNazioni.forEach(nation => {
      this.listaNazionalita.push(nation.demonym);
      this.listaNazionalita.sort((a, b) => a - b);
      this.listaNazionalita = Array.from(new Set(this.listaNazionalita));
    });
    return this.listaNazionalita;
    }

    getNationalityByCountryCode(contryCode: string) {
      for (let nation of this.arrayNazioni) {
        if (nation.countryCode == contryCode) {
          return nation.demonym}
        }
    }

    getCountryCodeByNationality(demonym: string) {
      for (let nation of this.arrayNazioni) {
        if (nation.demonym == demonym) {
          return nation.countryCode}
        }
    }

  getNations() {
    console.debug("getting nations");
    return this.httpClient.get<any>(this.env.NATIONS).subscribe(
    res => {
      this.countriesList = res;
      this.arrayNazioni=[];
      this.countriesList.forEach(nation=>{
        let nat = new Nation();
        nat.name = nation.translations.ita.common;
        nat.countryCode = nation.cca3;
        nat.prefix = nation.idd.root + nation.idd.suffixes[0];
        nat.demonym = nation.demonyms.eng.m;
        this.arrayNazioni.push(nat);
      });
      this.arrayNazioni = this.sortNations(this.arrayNazioni);
    });
  }

  sortNations(arr){
      function compare(a, b) {
        if (a.name < b.name)
          return -1;
        if (a.name> b.name)
          return 1;
        return 0;
      }
      arr.sort(compare);
      return arr;
    }

 }