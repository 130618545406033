import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Authentication } from '../authentication/authentication.service';
import { EnvironmentService } from '../environment/environment.service';


@Injectable()
export class UserService {

  constructor(public httpClient: HttpClient, private env: EnvironmentService,
              private auth: Authentication) {}

  deleteUser(member_id:string): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let params = {
      "member_id": member_id
    };
    return this.httpClient.delete(this.env.GET_USER, { headers: headers, params: params })
  }

  autocompleteCassette(type:string, term:string): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let params = {
      type: type,
      term: term
    };
    return this.httpClient.get(this.env.SUGGESTION, { headers: headers, params: params })
  }

}
