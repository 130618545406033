import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {EnvironmentService} from '../environment/environment.service';
import {Authentication} from '../authentication/authentication.service';

@Injectable()
export class MailService {

  constructor(public http: HttpClient,private env: EnvironmentService,
              private auth: Authentication) {

  }

  sendMailConfirm(email): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let body = {
      email: email
    };

    return this.http.post(this.env.CONFIRM_EMAIL, body, {headers: headers});
  }

  sendMailRights(email,selectedRights): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let body = {
      email: email,
      right:selectedRights
    };

    return this.http.post(this.env.RIGHT_REQUESTS, body, {headers: headers});
  }


  sendMailPortability(name,third_part_email,email,profile_data,interests,survey,vaults): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };

    let body = {
      name: name,
      email: email,
      email_third_party: third_part_email,
      profile_data: profile_data,
      survey: survey,
      interests: interests,
      vaults: vaults
    };

    return this.http.post(this.env.PORTABILITY, body, {headers: headers});
  }

}
