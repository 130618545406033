import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AppStorage } from "../../../services/app-storage/app-storage.service";
import { MyInAppBrowserService } from 'src/app/services/my-in-app-browser/my-in-app-browser.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';

@Component({
  selector: 'app-popup-salvadanaio',
  templateUrl: './popup-salvadanaio.page.html',
  styleUrls: ['./popup-salvadanaio.page.scss'],
})
export class PopupSalvadanaioPage{

  dontshowagain:boolean = false;
  popup: string;

  constructor(public navCtrl: NavController,public appStorage: AppStorage,
              public modalCtrl: ModalController, private env: EnvironmentService,
              private iab: MyInAppBrowserService) {}


  closeModal() {
    if (this.dontshowagain) {
        console.debug(this.popup);
        this.appStorage.setItem(this.popup, true);
      }
    this.modalCtrl.dismiss();
  }

  goToLinkMoreInfo() {
    this.iab.create(this.env.SALVADANAIO_URL, "_blank")
  }
}
