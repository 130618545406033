import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Platform } from "@ionic/angular";
import { Authentication } from "../authentication/authentication.service";
import { EnvironmentService } from "../environment/environment.service";


declare let cordova : any;

@Injectable()
export class PushUtil {
  constructor(public platform: Platform, private env: EnvironmentService, public httpClient: HttpClient,
              private auth: Authentication) {
  }

   PUSH_PLATFORM_ANDROID : string = "Android";
   PUSH_PLATFORM_IOS : string = "iOS";


  getPushPlatform() {
    if (this.platform.is('ios')) {
      return this.PUSH_PLATFORM_IOS;
    }
    return this.PUSH_PLATFORM_ANDROID;
  }

  trackANotification(id: string){
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let params = {
      id: id,
      action:'opened'
    };
    console.log(params);
    return this.httpClient.post(this.env.GET_NOTIFICATIONS +"/"+id+"/track",null, {headers: headers})
  }

  recoverNotification(toRead:string) : Observable<any>{
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let params = {
      read: "false"
    };
    return this.httpClient.get(this.env.GET_NOTIFICATIONS, {params:params,headers: headers})
  }

  postApiNotification(id:string){
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    let url=this.env.GET_NOTIFICATIONS;
    url += "/"+ id + "/read"
    console.log("postApi");
    console.log(id);
    return this.httpClient.post(url, null, {headers: headers})
  }

  getListReceivedNotifications(): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.get(this.env.GET_NOTIFICATIONS, {headers: headers})
  }

  dismissNotification(id: string): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.post(this.env.GET_NOTIFICATIONS+"/"+id+"/dismiss", null, {headers: headers})
  }

  requestPushPermission() : Observable<any>{
    const permissionObservable = new Observable((observer) => {
      if(this.platform.is('cordova') && this.platform.is('ios')) {
        cordova.plugins.diagnostic.requestRemoteNotificationsAuthorization({
          successCallback: () => {
            observer.next();
            observer.complete();
          },
          errorCallback: (err) => {
            observer.error(err);
            observer.complete();
          },
          types: [
            cordova.plugins.diagnostic.remoteNotificationType.ALERT,
            cordova.plugins.diagnostic.remoteNotificationType.SOUND,
            cordova.plugins.diagnostic.remoteNotificationType.BADGE
          ],
          omitRegistration: false
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
    return permissionObservable;
  }

  markAllNotificationsAsRead(){
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.auth.getAccessToken()
    };
    return this.httpClient.post(this.env.GET_NOTIFICATIONS + "/read", null, {headers: headers})
  }

}
