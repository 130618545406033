import { Component, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavController, NavParams} from '@ionic/angular';
import { EncryptionService } from "../../services/encryption/encryption.service";
import { MyPlatformService } from 'src/app/services/my-platform/my-platform.service';


export enum ProgressState {
  INIT = "init",
  KEYS = "keys",
  SESSION = "session",
  UPLOAD = "upload",
  COMPLETED = "completed"
}

export class Progress {
  state: ProgressState;
  level?: number;

  constructor(state:ProgressState, level?: number) {
    this.state = state;
    this.level = level ?? 10;
  }
}

@Component({
  selector: 'page-upload-process-modal',
  templateUrl: 'upload-process-modal.page.html',
  styleUrls: ['./upload-process-modal.page.scss'],

})

export class UploadProcessModalPage {

  progress: Progress;
  name:any;
  width:any;
  percentualeCaricamento: string;
  isWeb: boolean = false;

  constructor(public navCtrl: NavController, public navParams: NavParams,
              private encryptionService: EncryptionService, myPlatform: MyPlatformService,
              private chRef: ChangeDetectorRef, private modalCtrl: ModalController) {
                this.isWeb = myPlatform.isBrowser;
                this.progress = new Progress(ProgressState.INIT, 0);
              }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ionViewWillEnter() {
    this.encryptionService.encryptionProgress.subscribe((res: Progress) => {
      if (res.state !== ProgressState.COMPLETED) {
        this.progress = res;
        this.percentualeCaricamento = this.progress.level + "%";
      }
      else {
        this.percentualeCaricamento = 100 + "%";
        setTimeout(() => {
          this.closeModal();
        }, 2000);
      }
      try{
        this.chRef.detectChanges();
      }catch(err){
        console.error(err);
      }
    })
  }



}
