import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MenuController, Platform, NavController} from "@ionic/angular";
import {Authentication, UserInfo} from "../../services/authentication/authentication.service";
import {AppStorage} from "../../services/app-storage/app-storage.service";
import {EnvironmentService} from "../../services/environment/environment.service";
import {EventsService} from "../../services/events/events.service";
import {FirebaseService} from "../../services/firebase/firebase.service";
import {MyInAppBrowserService} from "../../services/my-in-app-browser/my-in-app-browser.service";
import {MyPlatformService} from '../../services/my-platform/my-platform.service';

@Component({
  selector: 'drawer-menu',
  templateUrl: './drawer-menu.component.html',
  styleUrls: ['./drawer-menu.component.scss'],
})

export class DrawerMenuComponent {

  platformIsIos = false; // se true aggiunge margin top alla navbar
  selectedIndex = 0;
  livello = 'Start';
  badgeCount : number = 0;
  userInfo: UserInfo;

  pages = [{
    label: 'Caveau',
    pageName:'tabs/caveau',
    name: 'weople-investi'
  }, {
    label: 'Gain&Relax',
    pageName:'tabs/prizes-and-offers',
    name: 'weople-premi'
  }, {
    label: 'Attiva diritti',
    pageName:'tabs/rights',
    name: 'weople-esercita'
  }];

  pagesBottom =[{
    label: 'Trasparenza e codice etico',
    pageLink: this.env.PAGINA_TRASPARENZA_URL,
    name: 'weople-trasparenza'
    //icon: 'assets/imgs/icon/trasparenza_color@3x.png'
  },{

    label: 'Notifiche',
    pageName: 'notifications',
    name: 'weople-notifiche'
    //icon: 'assets/imgs/icon/notifiche_color@3x.png'
  },
    {
      label: 'News',
      pageLink: this.env.PAGINA_NEWS_URL,
      name: 'weople-news'
      //icon: 'assets/imgs/icon/news_color@3x.png'
    },
    {
      label: 'Chi siamo',
      pageLink: this.env.PAGINA_CHI_SIAMO,
      name: 'weople-chi-siamo'
      //icon: 'assets/imgs/icon/chi_siamo_color@3x.png'
  }, {
      label: 'Aiuto',
      pageName: 'help',
      name: 'weople-help'
      //icon: 'assets/imgs/icon/aiuto_color@3x.png'
  }];

  constructor(private auth: Authentication, private appStorage: AppStorage,
              private env: EnvironmentService, public events: EventsService,
              private firebaseService:FirebaseService, private menuCtrl: MenuController,
              private myplt: MyPlatformService, private iab: MyInAppBrowserService,
              public navCtrl:NavController, public platform: Platform, private router: Router) {

    events.subscribe('selectedTab', (data) => {
      this.selectedIndex = data;
    });
    if (platform.is('ios')) {
      this.platformIsIos = true;
    }

    /* Recuperiamo l'oggetto userInfo salvata se presente */
    this.appStorage.getItem(Authentication.USER_INFO).then((userInfo:UserInfo) => {
      if(userInfo == undefined){
        return;
      }
      this.userInfo = userInfo;
      console.debug(userInfo);
    }).catch(error => {
      console.error(error)
    });

    /* Ascoltiamo l'evento on user refresh per aggiornare il valore */
    this.events.subscribe(EnvironmentService.ON_USER_REFRESH, (data) => {
      if(data == undefined){
        return;
      }
      let userInfo: UserInfo = data as UserInfo;
      this.userInfo = userInfo;
    });

    /* Ascoltiamo l'evento on badge count refresh per aggiornare il valore */
    this.events.subscribe(EnvironmentService.ON_BADGE_REFRESH, (data) => {
      this.badgeCount = data as number;
    });
  }

  goTo(page:any) {
    // se c'e' un attributo index, vuol dire che la pagina e' presente anche nella tab bar in quella posizione
    if (page.pageLink != null) {
      this.goToLink(page.pageLink);
    }
    else {
      this.navCtrl.navigateForward(page.pageName);
    }
    this.menuCtrl.close();
  }

  goToProfile(){
    this.menuCtrl.close();
    this.router.navigateByUrl('/profile')
  }

  goToLink(link:string){
    this.iab.create(link, "_blank")
  }

  logout() {
    this.menuCtrl.close();
    this.auth.logout().subscribe({next:() => {
      this.router.navigateByUrl('/registrati-o-accedi');
    }, error:(error) =>{
        console.error("error on auth service "+error);
      }});
    }
  }
