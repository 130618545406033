import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController} from '@ionic/angular';
import { Cassetta } from 'src/app/models/cassetta/cassetta.model';
import { PopupUtilService } from "../../../services/popup-util/popup-util.service";
import { AppStorage } from "../../../services/app-storage/app-storage.service";

@Component({
  selector: 'app-alert-tutorial-upload',
  templateUrl: './alert-tutorial-upload.page.html',
  styleUrls: ['./alert-tutorial-upload.page.scss'],
})
export class AlertTutorialUploadPage {
  dontshowagain = false;
  cassetta: Cassetta;
  fromHelp: boolean;

  constructor(public navCtrl: NavController,
    public modalCtrl: ModalController, public router: Router,
    public popupUtil: PopupUtilService, public appStorage:AppStorage) {
  }

  goToFirstPage() {
    this.saveDontShowAgainStatus();
    if (this.fromHelp){
      this.navCtrl.navigateBack('help');
    }else{
      let parametri={};
      parametri['cassetta']=this.cassetta
      this.router.navigateByUrl("upload-dati-archiviati",{state:parametri});
    }
    this.modalCtrl.dismiss(this.cassetta.vTypeCode);
  }

  closeModal(){
    this.saveDontShowAgainStatus();
    return this.modalCtrl.dismiss(this.cassetta.vTypeCode);
  }

  saveDontShowAgainStatus(){
    if (this.dontshowagain) {
      this.appStorage.setItem(this.popupUtil.ALERT_TUTORIAL_UPLOAD_DONT_SHOW_AGAIN+this.cassetta.vTypeCode, true);
    }
  }

}