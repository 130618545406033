<div [ngClass]="setSize()">
  <ion-col *ngIf="size === 'normal'" size="12">
      <ion-row class="ion-no-padding">
        <ion-col class="ion-no-padding ion-text-center" col-8>
            <span class="score-label">Livello attuale:
                <b>{{livello}}</b>
            </span>
        </ion-col>
        <ion-col class="ion-no-padding" size="4">
          <span class="score-guida" (click)="showPopupGuida()">guida</span>
        </ion-col>
      </ion-row>
  </ion-col>
  <div class="livello" *ngIf="size != 'normal'">
      <span class="score-label">Livello attuale:
        <b>{{livello}}</b>
      </span>
  </div>
  <div class="score-row2">
    <img *ngIf="size === 'normal'" src="assets/imgs/wecoin/wecoin_gold@3x.png" alt="wecoin logo" />
    <div class="header-divider" *ngIf="size === 'normal'"></div>
    <ion-grid classe="ion-no-padding">
      <ion-row class="score-row2 ion-no-padding">
        <span class="score-label ">Wecoin acquisiti</span>
        <div class="points">
          <span class="points-label acquisiti">{{punteggioAcquisito}}</span>
        </div>
      </ion-row>
      <ion-row class="score-row2 attesa ion-no-padding">
        <span class="score-label ">Wecoin in attesa</span>
        <div class="points">
          <span class="points-label">{{punteggioAttesa}}</span>
        </div>
      </ion-row>
    </ion-grid>
  </div>
</div>